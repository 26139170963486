import createRoutes from '../lib/createRoutes.mjs';

/**
 * Route declarations:
 *
 * Example:
 * `.add('/things/:thing_id', 'my_things.show', { as: 'my_thing' })`
 * - creates a route the given url, and passing `thing_id` as a query param
 * - Reads the file `pages/my_things/show` or `pages/my_things/show/index`
 * - Since `as: 'my_thing'` option is provided, this route's "name" is both: 'my_things.show' & 'my_thing'
 */

export default createRoutes()
  .index()
  .add('/privacy', 'privacy')
  .add('/terms', 'terms')
  .add('/onboarding', 'onboarding')
  .add('/users/:user_id', 'public_user')
  .add('/search/:location?', 'search')
  .namespace('/settings', 'settings', null, (a) => {
    a.index()
      .add('/payments', 'payments')
      .add('/payments_dashboard', 'payments_dashboard')
      .add('/profile', 'profile');
  })
  .namespace('/chats', 'chats', null, (a) => {
    a.index().add('/:chat_id', 'show');
  })
  .namespace('/listings', 'listings', null, (a) => {
    a.add('/:listing_id', 'show', { as: 'listing' }).add('/:listing_id/book', 'book');
  })
  .namespace('/bookings', 'bookings', null, (a) => {
    a.add('/:booking_id', 'show', { as: 'booking' })
      .add('/:booking_id/review', 'review')
      .add('/:booking_id/thanks', 'book_others');
  })
  .namespace('/host', 'host', null, (a) => {
    a.index()
      .add('/create', 'create')
      .add('/new', 'new')
      .add('/calendar', 'calendar')
      .namespace('/listings', 'listings', null, (b) => {
        b.index({ redirect: 'host' }).namespace('/:listing_id/manage', 'manage', null, (c) => {
          c.index({ redirect: 'host.listings.manage.overview' }).add('/overview', 'overview');
        });
      });
  })
  .namespace('/admin', 'admin', null, (a) => {
    a.index()
      .add('/kpi', 'kpi')
      .add('/historical', 'historical')
      .add('/charts', 'charts')
      .add('/feedback', 'feedback')
      .add('/social', 'social')
      .add('/finances', 'finances')
      .add('/newsletter', 'newsletter')
      .add('/holiday_profiles', 'holiday_profiles')
      .add('/map', 'map')
      .add('/coupons', 'coupons')
      .add('/admin_comments', 'admin_comments')
      .add('/leaderboard', 'leaderboard')
      .add('/chats', 'chats')
      .add('/chats/:chat_id', 'chats.show')
      .add('/users', 'users')
      .add('/users/:user_id', 'users.show')
      .add('/listings', 'listings')
      .add('/listings/:listing_id', 'listings.show')
      .add('/bookings', 'bookings')
      .add('/bookings/:booking_id', 'bookings.show')
      .add('/support', 'support')
      .add('/support/:case_id', 'support.show');
  });
