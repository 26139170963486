import { useCallback, useState } from 'react';

type UseCookieReturnValue = [string, (value: string, expiresInHours?: number) => void];

const useCookie = (name: string, defaultValue = ''): UseCookieReturnValue => {
  const [value, setValue] = useState(() => {
    if (typeof window !== 'undefined') {
      const cookie = document.cookie.split('; ').find((row) => row.startsWith(`${name}=`));
      if (cookie) {
        return cookie.split('=')[1];
      }
    }
    return defaultValue;
  });

  const updateCookie = useCallback(
    (newValue: string, expiresInHours?: number) => {
      if (typeof window !== 'undefined') {
        const expires = expiresInHours
          ? `; expires=${new Date(Date.now() + expiresInHours * 60 * 60 * 1000).toUTCString()}`
          : '';
        document.cookie = `${name}=${newValue}${expires}; path=/`;
      }
      setValue(newValue);
    },
    [name],
  );

  return [value, updateCookie];
};

export default useCookie;
