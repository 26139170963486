import clsx from 'clsx';
import { ReactNode, useCallback, useState } from 'react';

import Link from 'components/Link';
import Logo, { LetterMarkLogo, LetterMarkLogoWithUpstateNY } from 'components/Logo';
import { useRouterEvent } from 'lib/hooks/router';
import useAtTop from 'lib/hooks/useAtTop';

export const NavMenu = ({
  trigger,
  children,
}: {
  trigger: React.ReactNode | string;
  children: React.ReactNode;
}) => {
  return (
    <div className="navbar-item has-dropdown is-hoverable">
      {typeof trigger === 'string' ? <div className="navbar-link">{trigger}</div> : trigger}
      <div className="navbar-dropdown is-right is-boxed">{children}</div>
    </div>
  );
};

const BaseNav = ({
  topShadow = true,
  topDark = false,
  topTransparent = false,
  bottomShadow = true,
  bottomDark = false,
  brandDisabled = false,
  hideLogo = false,
  withUpstateNY = false,
  navbarStart,
  navbarEnd,
  navbarBrand,
  ctaButtonColor,
}: {
  topShadow?: boolean;
  topDark?: boolean;
  topTransparent?: boolean;
  bottomShadow?: boolean;
  bottomDark?: boolean;
  brandDisabled?: boolean;
  hideLogo?: boolean;
  withUpstateNY?: boolean;
  navbarStart?: React.ReactNode | ((props: any) => void);
  navbarEnd?: React.ReactNode | ((props: any) => void);
  navbarBrand?: React.ReactNode | ((props: any) => void);
  ctaButtonColor?: string;
}) => {
  const [open, setOpen] = useState(false);
  const atTop = useAtTop(20);

  const close = useCallback(() => setOpen(false), []);
  useRouterEvent('routeChangeStart', close);

  const toggle = () => setOpen((current) => !current);

  const isLight = open || (atTop ? !(topDark || topTransparent) : !bottomDark);
  const showShadow = atTop ? topShadow : bottomShadow;

  const renderProps = {
    topShadow,
    topDark,
    topTransparent,
    bottomShadow,
    bottomDark,
    showShadow,
    isLight,
    buttonColor: ctaButtonColor,
  };
  const renderPart = (
    part:
      | ReactNode
      | ((props: {
          topShadow: boolean;
          topDark: boolean;
          topTransparent: boolean;
          bottomShadow: boolean;
          bottomDark: boolean;
          showShadow: boolean;
          isLight: boolean;
          buttonColor?: string;
        }) => void),
  ) => {
    return (typeof part === 'function' ? part(renderProps) : part) || null;
  };
  navbarBrand = renderPart(navbarBrand);
  navbarStart = renderPart(navbarStart);
  navbarEnd = renderPart(navbarEnd);

  const hasMenu = !!(navbarStart || navbarEnd);

  return (
    <>
      <nav
        className={clsx(
          'navbar',
          showShadow && 'tw-shadow-md',
          !isLight && 'is-link',
          !open && topTransparent && atTop && 'is-transparent',
        )}
      >
        <div className={clsx('navbar-brand', !hasMenu && 'flex-1')}>
          {!hideLogo &&
            (withUpstateNY ? (
              <Link className="navbar-item" href="/">
                <span className="sr-only">Wanderglade</span>
                <LetterMarkLogoWithUpstateNY size={48} />
              </Link>
            ) : brandDisabled ? (
              <Link className="navbar-item" href="/">
                <LetterMarkLogo size={48} />
              </Link>
            ) : (
              <Link className="navbar-item" href="/">
                <span className="sr-only">Wanderglade</span>
                <Logo size={48} />
              </Link>
            ))}
          {navbarBrand}
          {hasMenu ? (
            <button
              type="button"
              className={clsx('navbar-burger burger', open && 'is-active')}
              onClick={toggle}
              tabIndex={0}
              aria-label="menu"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </button>
          ) : null}
        </div>
        {hasMenu ? (
          <div
            className={`navbar-menu tw-bg-transparent ${open ? 'is-active' : ''}`}
            style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
          >
            <div className="navbar-start">{navbarStart}</div>
            <div className="navbar-end">{navbarEnd}</div>
          </div>
        ) : null}
      </nav>

      <style jsx>{`
        button.navbar-burger {
          background: none;
          border: none;
        }

        nav {
          padding: 0 8px;
        }
      `}</style>
    </>
  );
};

export default BaseNav;
