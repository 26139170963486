import React from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';

import { HOST, MOBILE_APP } from 'lib/env';
import { absUrl } from 'lib/utils/url';

interface Image {
  src: string;
  width?: number;
  height?: number;
}

interface Article {
  published_time?: string;
  modified_time?: string;
}

interface Video {
  src: string;
  width?: number;
  height?: number;
  type?: string;
}

interface MetaGraphProps {
  title?: string;
  description?: string;
  keywords?: string[];
  article?: Article;
  image?: string | Image;
  video?: Video;
}

const MetaGraph: React.FC<MetaGraphProps> = ({
  title,
  description,
  keywords,
  article,
  image,
  video,
}) => {
  const pathname = useRouter()?.asPath || '';

  if (MOBILE_APP) return null;

  let imageObj: Image | undefined;
  if (image) {
    if (typeof image === 'string') {
      imageObj = { src: image };
    } else {
      imageObj = image;
    }
    imageObj.src = absUrl(imageObj.src) as string;
  }

  let type = 'website';
  if (article) {
    type = 'article';
  }

  // Include the video Open Graph tags if a video is provided
  let videoObj: Video | undefined;
  if (video) {
    if (typeof video === 'string') {
      videoObj = { src: video, type: 'video/mp4' }; // Default type is mp4
    } else {
      videoObj = video;
    }
    videoObj.src = absUrl(videoObj.src) as string;
  }

  return (
    <Head>
      <meta key="og:type" property="og:type" content={type} />
      <meta key="og:url" property="og:url" content={HOST + pathname} />
      {keywords ? <meta key="keywords" name="keywords" content={keywords.join(',')} /> : null}
      {description ? <meta key="description" name="description" content={description} /> : null}
      {description ? (
        <meta key="og:description" property="og:description" content={description} />
      ) : null}
      {description ? (
        <meta key="twitter:description" name="twitter:description" content={description} />
      ) : null}
      {article?.published_time ? (
        <meta
          key="article:published_time"
          property="article:published_time"
          content={article.published_time}
        />
      ) : null}
      {article?.modified_time ? (
        <meta
          key="article:modified_time"
          property="article:modified_time"
          content={article.modified_time}
        />
      ) : null}
      {imageObj && imageObj.src ? (
        <meta key="og:image" property="og:image" content={imageObj.src} />
      ) : null}
      {imageObj && imageObj.width ? (
        <meta key="og:image:width" property="og:image:width" content={imageObj.width.toString()} />
      ) : null}
      {imageObj && imageObj.height ? (
        <meta
          key="og:image:height"
          property="og:image:height"
          content={imageObj.height.toString()}
        />
      ) : null}
      {imageObj && imageObj.src ? (
        <meta key="twitter:image" name="twitter:image" content={imageObj.src} />
      ) : null}
      {videoObj && videoObj.src ? (
        <meta key="og:video" property="og:video" content={videoObj.src} />
      ) : null}
      {videoObj && videoObj.type ? (
        <meta key="og:video:type" property="og:video:type" content={videoObj.type} />
      ) : null}
      {videoObj && videoObj.width ? (
        <meta key="og:video:width" property="og:video:width" content={videoObj.width.toString()} />
      ) : null}
      {videoObj && videoObj.height ? (
        <meta
          key="og:video:height"
          property="og:video:height"
          content={videoObj.height.toString()}
        />
      ) : null}
      {title ? <meta key="og:title" property="og:title" content={title} /> : null}
      {title ? <meta key="twitter:title" name="twitter:title" content={title} /> : null}
      {title ? <title key="title">{title}</title> : null}
    </Head>
  );
};

export default MetaGraph;
