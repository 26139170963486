import * as _ from 'lodash-es';

export const LISTING_TITLE_LENGTH = { min: 5, max: 50 };
export const LISTING_DESCRIPTION_LENGTH = { min: 3, max: 15000 };

// reviews are integers in range [0, 4]
export const MAX_REVIEW = 4;

export const LISTING_PRICE = { min: 50, max: 25000 };

export const RANDOMIZE_LOCATION_METERS = { min: 600, max: 800 };

// ---

export const NIGHTS_MIN_OPTIONS = [
  { key: 1, label: '1 night' },
  { key: 2, label: '2 nights' },
  { key: 3, label: '3 nights' },
  { key: 4, label: '4 nights' },
  { key: 5, label: '5 nights' },
  { key: 6, label: '6 nights' },
  { key: 7, label: '1 week' },
  { key: 14, label: '2 weeks' },
  { key: 30, label: '1 month' },
  { key: 90, label: '3 months' },
  { key: 180, label: '6 months' },
  { key: 270, label: '9 months' },
  { key: 365, label: '1 year' },
];

export const NIGHTS_MAX_OPTIONS = [{ key: null, label: 'No limit' }, ...NIGHTS_MIN_OPTIONS];

export const BOOKING_NOTICE_LIMIT_OPTIONS = [
  { key: 0, label: 'Same day' },
  { key: 1, label: '1 day' },
  { key: 2, label: '2 days' },
  { key: 3, label: '3 days' },
  { key: 7, label: '7 days' },
];

export const HOW_FAR_CAN_GUESTS_BOOK_OPTIONS = [
  { key: null, label: 'No restriction' },
  { key: 7, label: '1 week' },
  { key: 14, label: '2 weeks' },
  { key: 30, label: '1 month' },
  { key: 90, label: '3 months' },
  { key: 180, label: '6 months' },
  { key: 365, label: '1 year' },
];

// matches `./amenityIcons.ts`
export const AMENITIES_OPTIONS = [
  { iconSrc: 'fa-solid fa-tint', label: 'Water', key: 'water' },
  { iconSrc: 'fa-solid fa-bolt', label: 'Electricity', key: 'electricity' },
  { iconSrc: 'fa-solid fa-shower', label: 'Shower', key: 'shower' },
  { iconSrc: 'fa-solid fa-shower', label: 'Laundry', key: 'laundry' },
  { iconSrc: 'fa-solid fa-fire', label: 'Firepit', key: 'firepit' },
  { iconSrc: 'fa-solid fa-television', label: 'TV', key: 'tv' },
  { iconSrc: 'fa-solid fa-snowflake', label: 'Air Conditioning', key: 'air_conditioning' },
  { iconSrc: 'fa-solid fa-hot-tub-person', label: 'Hot tub', key: 'hot_tub' },
  { iconSrc: 'fa-solid fa-water-ladder', label: 'Pool', key: 'pool' },
  { iconSrc: 'fa-solid fa-soap', label: 'Washer/Dryer', key: 'washer_dryer' },
  { iconSrc: 'fa-solid fa-wifi', label: 'High-speed wifi', key: 'wifi' },
  { iconSrc: 'fa-solid fa-fire-flame-curved', label: 'Outdoor grill', key: 'grill' },
  { iconSrc: 'fa-solid fa-mountain', label: 'Scenic views', key: 'scenic_views' },
  { iconSrc: 'fa-solid fa-wind', label: 'Sauna', key: 'sauna' },
  { iconSrc: 'fa-solid fa-water', label: 'Steamroom', key: 'steam_room' },
  { iconSrc: 'fa-solid fa-dumbbell', label: 'Gym', key: 'gym' },
  { iconSrc: 'fa-solid fa-utensils', label: 'Fully equipped kitchen', key: 'kitchen' },
];

export const AIRBNB_AMENITIES_MAPPING = [
  { airbnb: 'wifi', wanderglade: 'wifi' },
  { airbnb: 'tv', wanderglade: 'tv' },
  { airbnb: 'kitchen', wanderglade: 'kitchen' },
  { airbnb: 'washer', wanderglade: 'washer_dryer' },
  { airbnb: 'air conditioning', wanderglade: 'air_conditioning' },
  { airbnb: 'pool', wanderglade: 'pool' },
  { airbnb: 'hot tub', wanderglade: 'hot_tub' },
  { airbnb: 'grill', wanderglade: 'grill' },
  { airbnb: 'fire pit', wanderglade: 'firepit' },
  { airbnb: 'indoor fireplace', wanderglade: 'firepit' },
  { airbnb: 'exercise equipment', wanderglade: 'gym' },
  { airbnb: 'shower', wanderglade: 'shower' },
  { airbnb: 'view', wanderglade: 'scenic_views' },
];

export const LISTING_TYPES = [
  { iconSrc: 'fa-solid fa-igloo', label: 'Dome', key: 'dome' },
  { iconSrc: 'fa-solid fa-ship', label: 'Boathouse', key: ' boathouse' },
  { iconSrc: 'fa-solid fa-house-chimney-crack', label: 'Cabin', key: 'cabin' },
  { iconSrc: 'fa-solid fa-warehouse', label: 'Barn', key: 'barn' },
  { iconSrc: 'fa-solid fa-shop', label: 'Tiny Home', key: 'tiny_home' },
  { iconSrc: 'fa-solid fa-place-of-worship', label: 'Yurt', key: 'yurt' },
  { iconSrc: 'fa-solid fa-mountain', label: 'Farmhouse', key: 'farmhouse' },
  { iconSrc: 'fa-solid fa-landmark', label: 'Cottage', key: 'cottage' },
  { iconSrc: 'fa-solid fa-house-crack', label: 'Chalet', key: 'chalet' },
  { iconSrc: 'fa-solid fa-campground', label: 'Teepee', key: 'teepee' },
  // Architectural home
  { iconSrc: 'fa-solid fa-home', label: 'Architectural House', key: 'architectural_home' },
  // Luxury home
  { iconSrc: 'fa-solid fa-crown', label: 'Luxury House', key: 'luxury_home' },
] as const;

export const LISTING_TYPES_LABELS = LISTING_TYPES.map((e) => e.label);

export type ListingType = typeof LISTING_TYPES_LABELS[number];

export type ListingTypeKey = typeof LISTING_TYPES[number]['key'];

export const REFERRAL_BONUS_USD = 150;

export const AMENITIES_LABELS = _.mapValues(
  _.keyBy(AMENITIES_OPTIONS, (d) => d.iconSrc),
  (d) => d.label,
);

export const AMENITIES = AMENITIES_OPTIONS.map((amenity) => amenity.key);

export type AmenityKey = typeof AMENITIES_OPTIONS[number]['iconSrc'];

const POLICY_COLORS = {
  flexible: { background: '#2EBC78', border: '#018948' },
  moderate: { background: '#FFE08A', border: '#DAB757' },
  intermediate: { background: '#FF9F76', border: '#E06C3A' },
  strict: {
    background: '#F95C78',
    border: '#F3133B',
  },
};

export const CANCELLATION_POLICIES = [
  {
    key: 'flexible',
    label: 'Flexible',
    desc: 'The guest can cancel up to 24 hours before the scheduled date, and are eligible for a full-refund minus the service fee.',
    style: POLICY_COLORS.flexible,
    refundRatio: 1,
    cancelMaxDaysBefore: 1,
  },
  {
    key: 'moderate',
    label: 'Moderate',
    desc: 'The guest can cancel their booking up to three days before their scheduled stay and are eligible for a 50% refund minus the service fee.',
    style: POLICY_COLORS.moderate,
    refundRatio: 0.5,
    cancelMaxDaysBefore: 3,
  },
  {
    key: 'intermediate',
    label: 'Intermediate',
    desc: 'The guest can cancel up to 4 weeks before arrival for a full refund, up to 3 weeks for a 50% refund, and no refund within 2 weeks of the scheduled stay.',
    style: POLICY_COLORS.intermediate,
    refundRatio: 1,
    cancelMaxDaysBefore: 1,
  },
  {
    key: 'strict',
    label: 'Strict',
    desc: 'All bookings are final, and no refunds are awarded to no-shows.',
    style: POLICY_COLORS.strict,
    refundRatio: null,
    cancelMaxDaysBefore: null,
  },
] as const;

export type CancellationPolicy = typeof CANCELLATION_POLICIES[number];

export type CancellationPolicyKey = typeof CANCELLATION_POLICIES[number]['key'];

export const CANCELLATION_POLICIES_MAP = _.keyBy(CANCELLATION_POLICIES, (d) => d.key);

// We assume if a rule is contained in
// listing.rules, then it is allowed
// Everything else should be a negation
export const LISTING_RULES = [
  { key: 'IN', label: 'Infants', yesIcon: 'fa-baby', noIcon: 'fa-baby' },
  { key: 'PE', label: 'Pets', yesIcon: 'fa-paw', noIcon: 'fa-paw' },
  { key: 'SM', label: 'Smoking', yesIcon: 'fa-smoking', noIcon: 'fa-smoking-ban' },
  { key: 'FP', label: 'Fire pits', yesIcon: 'fa-fire', noIcon: 'fa-fire' },
  { key: 'EP', label: 'Events or Parties', yesIcon: 'fa-glass-cheers', noIcon: 'fa-glass-cheers' },
] as const;

export type ListingRule = typeof LISTING_RULES[number];

export type ListingRuleKey = typeof LISTING_RULES[number]['key'];

export const GROUND_ANGLE_OPTIONS = [
  { key: 'flat', label: 'Flat ground' },
  { key: 'medium', label: 'Mostly flat ground' },
  { key: 'steep', label: 'Steep ground' },
] as const;

export const GROUND_ANGLE_MAP = _.keyBy(GROUND_ANGLE_OPTIONS, (d) => d.key);

export type GroundAngleType = typeof GROUND_ANGLE_OPTIONS[number]['key'];
