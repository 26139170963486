import clsx from 'clsx';
import React from 'react';
import { Control, ControllerRenderProps, FieldError, useController } from 'react-hook-form';
// @ts-expect-error missing types
import { UnmountClosed } from 'react-collapse';
import { usePrevious } from 'react-use';

import { Checkbox } from 'components/form/CheckRadio';
import LimitedTextInput from 'components/form/LimitedTextInput';
import { parseNum } from 'lib/utils';
import { useSequentialId } from 'lib/utils/sequentialId';

const getErrorMessage = (err: { message?: string } | string | null | undefined): string | null => {
  if (typeof err === 'string' && err.length > 0) return err;
  else if (typeof err === 'object' && err !== null) return getErrorMessage(err.message);
  else return null;
};

type WrapperProps = {
  inputId?: string;
  label?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  error?: FieldError;
  showError?: boolean;
  helpText?: React.ReactNode;
  children?: React.ReactNode;
};

export const FieldWrapper: React.FC<WrapperProps> = ({
  style,
  className,
  error,
  showError = false,
  helpText,
  children,
}) => {
  const controlContent = children;

  const helpContent = helpText ? (
    <p className="help font-secondary is-size-6" style={{ color: '#565940' }}>
      {helpText}
    </p>
  ) : null;

  const errorMessage = getErrorMessage(error);
  const prevMessage = usePrevious(errorMessage);
  const errorContent = (
    <UnmountClosed isOpened={showError && !!errorMessage}>
      <p className="is-size-7" style={{ paddingTop: '0.25rem', margin: 0, color: '#E07A5F' }}>
        {errorMessage || prevMessage}
      </p>
    </UnmountClosed>
  );

  return (
    <div className={clsx('field mb-5', className)} style={style}>
      {controlContent}
      {helpContent}
      {errorContent}
    </div>
  );
};

export const NumberInput = (f: RenderProps) => {
  return (
    <input
      className={clsx('input', f.className, f.error && 'is-danger')}
      type="number"
      step={0.01}
      name={f.name}
      value={f.value ?? ''}
      onChange={(e) => f.onChange(parseNum(e.target.value))}
      onBlur={f.onBlur}
      id={f.inputId}
    />
  );
};

type RenderProps = Omit<ControllerRenderProps, 'ref'> & {
  error?: FieldError;
  inputId?: string;
  rules?: any;
  style?: any;
  className?: string;
};

type FieldProps = WrapperProps & {
  name: string;
  type?: string;
  inputClassName?: string;
  render?: (f: RenderProps) => React.ReactNode;
  as?: React.ElementType;
  required?: boolean;
  rules?: any;
  control?: Control<any>;
  isTextArea?: boolean;
  charProgressMode?: 'all' | 'error';
  placeholder?: string;
  disabled?: boolean;
};

const Field: React.FC<FieldProps> = ({
  name,
  label,
  style,
  className,
  inputClassName,
  render,
  as: As,
  required = false,
  showError = false,
  rules = {},
  control,
  helpText,
  placeholder,
  disabled,
  ...rest
}) => {
  const inputId = `${useSequentialId()}-form-input`;

  const { field, fieldState } = useController({
    control,
    name,
    rules: { required, ...rules },
  });

  const props: RenderProps = {
    name: field.name,
    onBlur: field.onBlur,
    onChange: field.onChange,
    value: field.value,
    error: fieldState.error,
    inputId,
    rules,
    style: {
      color: '#565940',
      backgroundColor: '#F2E0C9',
      border: fieldState.error ? '2px solid #F95C78' : '1px solid #BFBDA3',
    },
  };

  return (
    <FieldWrapper
      style={style}
      className={className}
      error={fieldState.error}
      showError={showError}
      helpText={helpText}
    >
      <div className="font-secondary" style={{ position: 'relative' }}>
        <label
          className="px-4 is-size-7"
          style={{
            color: '#565940',
            backgroundColor: '#CBB89B',
            width: 'max-content',
            position: 'absolute',
            zIndex: 1,
            top: -10,
            left: 10,
            borderRadius: 15,
          }}
        >
          <span>{label}</span>
          {label && <span className="ml-2">{required ? '✽' : ''}</span>}
        </label>
        {As ? (
          <As placeholder={placeholder} {...rest} {...props} />
        ) : render ? (
          render(props)
        ) : rest.type === 'number' ? (
          <div className="control is-expanded">
            <NumberInput className={clsx('!tw-bg-champagne !tw-py-5', inputClassName)} {...props} />
          </div>
        ) : (
          <div className="control is-expanded">
            {/* @ts-expect-error missing types */}
            <LimitedTextInput
              {...rest}
              {...props}
              placeholder={placeholder}
              className={clsx('!tw-bg-champagne', inputClassName)}
              charProgressMode={
                rest.charProgressMode !== undefined ? rest.charProgressMode : 'error'
              }
              style={{
                color: disabled ? '#858476' : '#565940',
                border: props.error ? '2px solid #F95C78' : '1px solid #BFBDA3',
                paddingBlock: 20,
              }}
              disabled={disabled}
            />
          </div>
        )}
      </div>
    </FieldWrapper>
  );
};
export default Field;

export const CheckboxField: React.FC<FieldProps> = (props) => {
  return (
    <Field
      {...props}
      render={(f) => (
        <Checkbox
          {...f}
          value={!!f.value}
          //
          onChange={(v: any) => f.onChange(!!v)}
        />
      )}
    />
  );
};
