import { useAuthUser } from 'lib/hooks/auth';

import { NavLink } from 'components/Link';
import Logo from 'components/Logo';
import { useNotifications } from 'lib/notificationSubscription';

import { NotifDot } from './Navbars/MainNav';

const NavItem = ({ children, href, icon, exact, isActive, badgeCount }) => {
  return (
    <NavLink
      className="navbar-item is-expanded has-text-centered"
      href={href}
      isActive={isActive}
      unselectable="on"
      onSelect={() => false}
      exact={exact}
    >
      <div className="is-relative">
        {icon}
        {badgeCount > 0 ? <NotifDot style={{ top: 0, right: -10 }} /> : null}
      </div>
      <p className="is-size-7">{children}</p>
    </NavLink>
  );
};

const NavItems = () => {
  const authUser = useAuthUser();

  const { messageCount, hostNotifCount, guestNotifCount } = useNotifications();

  return (
    <>
      <NavItem
        href="/"
        isActive={(pathname, linkPathname) =>
          linkPathname === pathname ||
          pathname.startsWith('/search') ||
          pathname.startsWith('/listings') ||
          pathname.startsWith('/users')
        }
        icon={<i className="fa fa-compass" />}
      >
        Explore
      </NavItem>
      <NavItem
        href="/host"
        isActive={(pathname, linkPathname) =>
          linkPathname === pathname || pathname.startsWith('/settings/payments_dashboard')
        }
        icon={<i className="fa fa-handshake" />}
        badgeCount={hostNotifCount}
      >
        Host
      </NavItem>
      <NavItem
        href="/trips"
        isActive={(pathname, linkPathname) =>
          linkPathname === pathname || pathname.startsWith('/bookings')
        }
        icon={<Logo size={40} style={{ verticalAlign: 'top', marginTop: 2 }} />}
        badgeCount={guestNotifCount}
      >
        Trips
      </NavItem>
      <NavItem href="/chats" icon={<i className="fa fa-comment-alt" />} badgeCount={messageCount}>
        Chats
      </NavItem>
      <NavItem
        href={authUser ? '/settings' : '/sign_up'}
        isActive={(pathname, linkPathname) =>
          linkPathname === pathname ||
          (!authUser && pathname === '/login') ||
          (!pathname.startsWith('/settings/payments_dashboard') &&
            pathname.startsWith('/settings/'))
        }
        icon={<i className="fa fa-user" />}
      >
        {authUser ? 'Profile' : 'Login'}
      </NavItem>
    </>
  );
};
// @typedef {Object} BottomMobileNavProps
// @property {ReactNode} [navbarBrand]
// @property {ReactNode} children
/**
 * @param {BottomMobileNavProps} props
 */
export default function BottomMobileNav({ navbarBrand = <NavItems />, children }) {
  return (
    <>
      <nav className="navbar is-fixed-bottom mobile-bottom-nav !tw-bg-off-white" role="navigation">
        {navbarBrand ? <div className="navbar-brand">{navbarBrand}</div> : null}
        {children}
      </nav>

      <style jsx global>{`
        @import 'styles/variables';

        .mobile-bottom-nav {
          // z-index: 32 !important;

          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
          min-height: auto;

          .navbar-brand {
            font-size: 24px;
            float: none;
            margin: 0 auto;
            min-height: inherit;
          }

          .navbar-item {
            flex: 1;
            font-size: 24px;

            padding-top: 5px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            user-select: none;

            $transition: 200ms ease;

            .fa,
            svg {
              color: $grey;
              transition: color $transition;
            }

            p {
              color: $grey;
              transition: text-shadow $transition, color $transition;
              line-height: 1;
            }

            &.is-active {
              background-color: transparent !important;
              border-bottom: none !important;

              .fa,
              svg {
                color: $link;
              }

              p {
                $s: 0.25px;
                text-shadow: (-$s) (-$s) 0 currentColor, $s (-$s) 0 currentColor,
                  (-$s) $s 0 currentColor, $s $s 0 currentColor;
                color: $dark !important;
              }
            }
          }
        }
      `}</style>
    </>
  );
}
