import Modal from 'components/Modal';
import { prompts } from 'components/alert';
import { LoginForm } from 'components/form/LoginForm';
import { getUser } from 'lib/auth';
import { getUrl } from 'lib/router';
import { trackError } from 'lib/tracking';

const LoginPrompt: React.FC<{
  active: boolean;
  onComplete: (loggedIn: boolean) => void;
  fromPath?: string;
  referrerName?: string;
  /** Start on the login page instead of signup page */
  isLogin?: boolean;
}> = ({ active, onComplete, fromPath, referrerName, isLogin }) => {
  return (
    <Modal
      active={active}
      onClose={() => {
        onComplete(false);
      }}
      footer={null}
    >
      <LoginForm
        isModal
        onClose={() => {
          onComplete(false);
        }}
        onLoggedIn={() => {
          onComplete(true);
        }}
        fromPath={fromPath}
        referrerName={referrerName}
        isLogin={isLogin}
      />
    </Modal>
  );
};

export const requireLogin = async (fromPath?: string, isLogin = false) => {
  let authUser = getUser();
  if (authUser) return authUser;

  const loggedIn = await prompts.custom(LoginPrompt, { fromPath, isLogin });

  authUser = getUser();

  if (loggedIn && !authUser) trackError('Logged in but no user found in requireLogin');

  if (!loggedIn) return null;

  return authUser;
};

/**
 * @returns Whether or not login succeeded
 */
export const referHostSignup = async ({ referrerName }: { referrerName: string }) => {
  if (getUser()) return true;

  const loggedIn = await prompts.custom(LoginPrompt, {
    fromPath: getUrl('host.create'),
    referrerName,
  });

  return !!loggedIn;
};

export const handleReauthenticationRequest = async (fn: () => Promise<void>) => {
  try {
    const res = await fn();
    return res;
  } catch (err) {
    if (
      err.code === 'auth/requires-recent-login' ||
      err.message === 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN'
    ) {
      const loggedIn = await prompts.custom(LoginPrompt, {
        fromPath: '/',
        customTitle: (
          <span className="is-size-5 is-block">Please login again to confirm this operation.</span>
        ),
        loginOnly: true,
        isNewUser: false,
      });

      if (!loggedIn) {
        throw new Error('Operation canceled');
      }
    } else {
      throw err;
    }
  }
};
