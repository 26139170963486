import Head from 'next/head';
import { useEffect } from 'react';

import Layout from 'components/Layout';
import LazyImage from 'components/LazyImage';
import Link from 'components/Link';
import { IS_DEV_ENV } from 'lib/env';
import { trackError } from 'lib/tracking';
import {
  BAD_REQUEST,
  FORBIDDEN,
  NOT_FOUND,
  SERVER_ERROR,
  UNAUTHORIZED,
  UNKNOWN,
} from 'lib/utils/errors';

import image404 from 'images/404.gif';

const firebaseCodeToNum = (code) => {
  switch (code) {
    case 'permission-denied':
      return FORBIDDEN;
    default:
      return code;
  }
};

export const getErrorMessage = (code, customMessage) => {
  code = firebaseCodeToNum(code);

  switch (code) {
    case FORBIDDEN:
    case UNAUTHORIZED:
      return [code, customMessage || "You don't have access to this content!"];
    case NOT_FOUND:
      return [code, customMessage || 'Sorry, we could not find the thing you were looking for.'];
    case BAD_REQUEST:
      return [code, customMessage || 'Something unexpected occurred... Please try again'];
    default:
      return [
        code ?? UNKNOWN,
        // only show custom message on dev
        (IS_DEV_ENV && customMessage) || 'Something unexpected occurred... Please try again',
      ];
  }
};

const ErrorPage = ({ code, message, __trackError: err, errorReported }) => {
  const [displayCode, displayMessage] = getErrorMessage(code, message);

  useEffect(() => {
    // Workaround for https://github.com/vercel/next.js/issues/8592
    if (err && !errorReported) trackError(err);
  }, [err, errorReported]);

  return (
    <Layout>
      <Head>
        <title>{displayMessage}</title>
      </Head>

      <section className="hero is-fullheight-flex">
        <div className="hero-body">
          <div className="container">
            <br />
            <br />
            <br />
            <div className="columns">
              <div className="column is-5">
                <p style={{ fontSize: 80, fontWeight: 'bold' }}>Oops!</p>
                <h1 className="is-size-3 has-text-grey">{displayMessage}</h1>
                <br />
                <p className="has-text-grey">Error Code: {displayCode}</p>
                <br />
                <p>
                  <Link href="/">&#x2190; Go Home</Link>
                </p>
              </div>
              {displayCode === NOT_FOUND && (
                <div className="column is-6 is-offset-1 shadowed">
                  <LazyImage src={image404} title="Bear not found" alt="404" />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

const logger = (...args) => {
  args = args.map((a) => {
    if (a && typeof a === 'object') {
      try {
        return JSON.stringify(a);
      } catch {}
    }
    return a;
  });
  console.error(...args);
};

const VALID_RES_STATUS_CODES = [NOT_FOUND, FORBIDDEN, BAD_REQUEST, UNAUTHORIZED, SERVER_ERROR];

ErrorPage.getInitialProps = async ({ res, err, asPath, query }) => {
  const code = err?.code ?? err?.statusCode ?? res?.statusCode ?? query?.statusCode;

  if (err) await trackError(err, { asPath });

  logger('_error page', {
    code,
    errCode: err?.code,
    errStatusCode: err?.statusCode,
    resStatusCode: res?.statusCode,
    query,
  });

  if (res) res.statusCode = VALID_RES_STATUS_CODES.includes(code) ? code : 500;

  return { code, message: err?.message, errorReported: true };
};

export default ErrorPage;
