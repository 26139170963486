import { cn } from 'lib/utils/cn';
import { ProfileDoc } from 'schemas/types';

import LazyImage from 'components/LazyImage';

export type ProfileSize = keyof typeof sizes;

const sizes = {
  xs: 32,
  sm: 48,
  md: 64,
  lg: 96,
  xl: 128,
};

const ProfileAvatar = ({
  profile,
  src = profile?.avatar,
  username = profile?.name,
  className,
  size = 'md',
  bordered = false,
}: {
  profile: ProfileDoc;
  src?: string | null;
  username?: string;
  className?: string;
  size?: ProfileSize;
  bordered?: boolean;
}) => {
  const dim = sizes[size] || sizes.md;

  return (
    <div
      className={cn(
        'tw-rounded-full tw-overflow-hidden tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center',
        bordered && 'tw-border tw-border-dark-champagne ',
      )}
    >
      {src ? (
        <LazyImage
          src={src}
          alt={`${username || 'user'} profile picture`}
          style={{ width: dim, height: dim }}
          // load double the size of the avatar for high DPI devices
          width={dim * 2}
          height={dim * 2}
          className={cn(className)}
        />
      ) : (
        <div
          style={{ width: dim, height: dim }}
          className={cn('tw-flex tw-items-center tw-justify-center', className)}
        >
          <i className="fa fa-user fa-2xl" aria-hidden="true" />
        </div>
      )}
    </div>
  );
};

export default ProfileAvatar;
