import React, { useEffect, useState } from 'react';

import Link from 'components/Link';
import { signOut } from 'lib/auth';
import { useAuthUser, useUserPermissions } from 'lib/hooks/auth';
import Router from 'lib/router';

import { DocId } from 'schemas/types';

import { requireLogin } from 'components/form/LoginPrompt';

import ProfileAvatar from 'components/ProfileAvatar';
import { getPublicProfile } from 'lib/userProfile';

import BaseNav from './Base';

const logout = () => signOut().then(() => Router.push('/'));

export const NotifDot = ({ style }: { style?: React.CSSProperties }) => {
  return <span className="navbar-item__notif-dot" aria-label="Action required" style={style} />;
};

export const AccountItem = ({ userId }: { userId: DocId | undefined }) => {
  const isAdmin = useUserPermissions().includes('admin');
  const [profile, setProfile] = useState<any>(undefined);

  useEffect(() => {
    const fetchProfile = async () => {
      if (userId) {
        const d = await getPublicProfile(userId);
        setProfile(d);
      }
    };

    fetchProfile();
  }, [userId]);

  if (!userId) return null;

  return (
    <div className="navbar-item has-dropdown is-hoverable">
      <div className="navbar-link tw-items-center !tw-hidden md:!tw-flex">
        <ProfileAvatar profile={profile} size="sm" bordered />
      </div>
      <div className="navbar-dropdown is-right is-boxed tw-font-sans-serif">
        {isAdmin && (
          <>
            <hr className="navbar-divider" />
            <Link className="navbar-item" href="/admin">
              <span aria-hidden>&#128274;</span> Admin
            </Link>
          </>
        )}
        <hr className="navbar-divider" />{' '}
        <a className="navbar-item !tw-px-4" tabIndex={0} href={`/users/${userId}`}>
          Profile
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
        <a className="navbar-item !tw-px-4" role="button" tabIndex={0} onClick={logout}>
          Logout
        </a>
      </div>
    </div>
  );
};

const NavbarEnd = ({
  isLight,
  cta,
  ctaLink,
  loginRequired,
}: {
  isLight: boolean;
  cta: string;
  ctaLink: string;
  loginRequired: boolean;
}) => {
  const authUser = useAuthUser();

  const handleClick = async () => {
    const toPath = ctaLink;
    if (!(await requireLogin(toPath))) return;
    Router.push(toPath);
  };

  if (authUser)
    return (
      <>
        <span className="flex items-center tw-font-light tw-text-[14px] tw-py-3 md:tw-px-3 md:tw-py-0">
          <Link
            className="md:tw-text-center tw-text-left tw-w-full tw-inline-block tw-font-sans-serif tw-bg-transparent tw-border-b md:tw-border tw-border-terra tw-text-terra tw-font-medium tw-py-2 tw-px-4 md:tw-rounded tw-transition-colors tw-duration-300  hover:tw-bg-terra hover:tw-text-white"
            href={ctaLink}
          >
            {cta}
          </Link>
        </span>
        <AccountItem userId={authUser.uid} />
      </>
    );

  return (
    <>
      <span className="navbar-item">
        {loginRequired ? (
          <button
            className="tw-inline-block tw-font-sans-serif tw-bg-transparent tw-border tw-border-terra tw-text-terra tw-font-medium tw-py-2 tw-px-4 tw-rounded tw-transition-colors tw-duration-300  hover:tw-bg-terra hover:tw-text-white"
            onClick={handleClick}
            type="button"
          >
            {cta}
          </button>
        ) : (
          <Link
            className="tw-inline-block tw-font-sans-serif tw-bg-transparent tw-border tw-border-terra tw-text-terra tw-font-medium tw-py-2 tw-px-4 tw-rounded tw-transition-colors tw-duration-300  hover:tw-bg-terra hover:tw-text-white"
            href={ctaLink}
          >
            {cta}
          </Link>
        )}
      </span>
    </>
  );
};

const CTANav = ({ cta = 'Get Started', ctaLink = '#', loginRequired = false, ...props }) => {
  return (
    <BaseNav
      {...props}
      navbarEnd={({ isLight }) => (
        <NavbarEnd isLight={isLight} cta={cta} ctaLink={ctaLink} loginRequired={loginRequired} />
      )}
    />
  );
};

export default CTANav;
