import type firebase from 'firebase/compat/app';

import { getDoc, updateDoc } from 'lib/utils/firestore';
import { durationToMillis, timestamp } from 'lib/utils/time';
import db from 'lib/db/shared';

const isThenable = <T>(v: any): v is PromiseLike<T> =>
  v !== null && typeof v === 'object' && typeof v.then === 'function';

const callMaybePromise = <T>(v: T | PromiseLike<T>, callOn: (val: T) => T): PromiseLike<T> | T => {
  if (isThenable(v)) return v.then(callOn);
  else return callOn(v);
};

class LocalCache {
  prefix: string;
  constructor(prefix = '') {
    this.prefix = prefix;
  }

  get<T>(key: string): T | undefined {
    try {
      const res = localStorage.getItem(this.prefix + key);
      return res !== null ? JSON.parse(res) : undefined;
    } catch {}

    return undefined;
  }

  set<T>(key: string, value: T): void {
    try {
      localStorage.setItem(this.prefix + key, JSON.stringify(value));
    } catch {}
  }

  remove(key: string): void {
    try {
      localStorage.removeItem(this.prefix + key);
    } catch {}
  }

  fetch<T>(key: string, fn: () => T | PromiseLike<T>, { force = false } = {}): T | PromiseLike<T> {
    const cachedValue = this.get<T>(key);
    if (!force && cachedValue !== undefined) return cachedValue;

    return callMaybePromise(fn(), (value) => {
      this.set(key, value ?? null); // convert undefined to null
      return value;
    });
  }
}

export const localCache = new LocalCache('wanderglade:');

type DataCacheDoc = {
  expire_at: firebase.firestore.Timestamp;
  data: any;
};

/**
 * Stores cached data in a temporary firestore document.
 * Can only be used server-side, not client-side.
 *
 * NOTE: data stored in this cache must be a valid Firestore document value
 * i.e. it is JSON serializable e.g. `new Date()` is invalid
 *
 * TODO: cleanup expired documents in a cron task
 */
class DbCache {
  cacheCollec = db.firestore.collection(
    'data_cache',
  ) as firebase.firestore.CollectionReference<DataCacheDoc>;

  async remove(...cacheKeys: string[]) {
    const batch = db.batch() as firebase.firestore.WriteBatch;
    for (const cacheKey of cacheKeys) batch.delete(this.cacheCollec.doc(cacheKey));
    await batch.commit();
  }

  async get<T>(cacheKey: string): Promise<T | undefined> {
    const ref = this.cacheCollec.doc(cacheKey);

    const dataCache = await getDoc(ref, { fail: false });

    return dataCache?.data;
  }

  async fetch<T>(
    cacheKey: string,
    fn: () => Promise<T>,
    {
      expireIn = '10 minutes',
      force = false,
    }: { expireIn?: number | string; force?: boolean } = {},
  ): Promise<T> {
    const ref = this.cacheCollec.doc(cacheKey);

    const dataCache = await getDoc(ref, { fail: false });

    if (typeof expireIn === 'string') expireIn = durationToMillis(expireIn) ?? 0;

    if (!force && dataCache?.expire_at && Date.now() < dataCache.expire_at.toMillis())
      return dataCache.data;

    const newData = await fn();

    await updateDoc(ref, { data: newData, expire_at: timestamp(Date.now() + expireIn) }, true);

    return newData;
  }
}

export const dbCache = new DbCache();
