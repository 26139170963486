import type firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';

import { getCachedAuthUser, getUser, onAuthStateChanged } from 'lib/auth';
import { C } from 'lib/db/shared';
import { MOBILE_APP } from 'lib/env';
import { useDoc } from 'lib/hooks/firestore';
import { AdminAction } from 'schemas/types';

let didFirstRender = MOBILE_APP;
export const useAuthUser = () => {
  // it'd be nice if we could use React context to populate `authUser` during SSR so we can delete `getCachedAuthUser()`,
  // but some pages are static and don't have SSR...
  const [authUser, setAuthUser] = useState<firebase.User | null | undefined>(() => {
    return !didFirstRender ? undefined : getUser();
  });

  useEffect(() => {
    didFirstRender = true;

    // TODO: `getCachedAuthUser` is super hacky!
    const cachedAuthUser = getUser() || getCachedAuthUser();
    if (cachedAuthUser) setAuthUser(cachedAuthUser);

    return onAuthStateChanged(setAuthUser);
  }, []);

  return authUser;
};

export const useUserPermissions = () => {
  const authUser = useAuthUser();

  const { data: adminMeta } = useDoc(authUser ? C.admins.doc(authUser.uid) : undefined);

  return adminMeta?.permissions || [];
};

export const useDocsEditActions = (actions: AdminAction[] = []) => {
  const permissions = useUserPermissions();
  return permissions.includes('docs.edit') ? actions : [];
};
