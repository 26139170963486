import clsx from 'clsx';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import FocusLock from 'react-focus-lock';
import { useKeyPressEvent } from 'react-use';

import { useIsMobile } from 'components/Responsive';
import { useHijackBrowserBack } from 'lib/hooks/router';
import { useLockScroll } from 'lib/hooks/useLockScroll';
import useTransition from 'lib/hooks/useTransition';
import { parseForm } from 'lib/utils/form';
import { useSequentialId } from 'lib/utils/sequentialId';
import Button from './form/Button';

const DEFAULT_LABELS = {
  cancel: 'Cancel',
  confirm: 'Confirm',
};

type ModalProps<IsForm extends boolean> = {
  active?: boolean;
  onConfirm?: (data: IsForm extends true ? Record<string, unknown> : null) => MaybePromise<void>;
  onClose?: () => void;
  header?: React.ReactNode;
  confirmClass?: string;
  footer?: React.ReactNode;
  isForm?: boolean;
  confirmDisabled?: boolean;
  disableOverlayClose?: boolean;
  largeWidth?: boolean;
  fullScreen?: boolean;
  loading?: boolean;
  labels?: { cancel: React.ReactNode; confirm: React.ReactNode };
  modalClassName?: string;
  buttonsClass?: string;
  children?: React.ReactNode;
};

const Modal = <IsForm extends boolean = false>({
  active = false,
  onConfirm,
  onClose,
  header,
  confirmClass,
  footer,
  isForm = false,
  confirmDisabled = false,
  disableOverlayClose = false,
  largeWidth = false,
  fullScreen: forceFullScreen,
  loading = false,
  labels = DEFAULT_LABELS,
  modalClassName,
  buttonsClass,
  children,
}: ModalProps<IsForm>) => {
  const sid = useSequentialId();
  const titleId = header ? `dialog-title-${sid}` : undefined;

  const Card = isForm ? 'form' : 'div';

  const [submittingForm, setSubmittingForm] = useState(false);

  const isMobile = useIsMobile();
  const fullScreen = forceFullScreen ?? isMobile; // if `forceFullScreen` is null/undefined, show fullScreen by default only on mobile

  useKeyPressEvent('Escape', active && onClose ? onClose : undefined);

  useHijackBrowserBack(
    active && onClose
      ? () => {
          onClose();
          return false; // prevent default back button behavior
        }
      : null,
  );

  const state = useTransition(active, 300);

  const lockScrollRef = useLockScroll(
    !!(active && state),
  ) as unknown as React.MutableRefObject<HTMLDivElement | null>;

  return (
    <>
      {!state
        ? null
        : createPortal(
            <FocusLock
              returnFocus
              as="div"
              className={clsx('modal is-active', fullScreen && 'modal--fullscreen', modalClassName)}
            >
              {!fullScreen && (
                <div
                  className={clsx(
                    'modal-background',
                    (state === 'in' || state === 'active') && 'fade-in',
                  )}
                  aria-hidden
                  onClick={!disableOverlayClose && onClose ? onClose : undefined}
                />
              )}

              <Card
                className={clsx(
                  'modal-card',
                  !fullScreen && 'md:tw-rounded-[12px]',
                  (state === 'in' || state === 'active') && 'fade-in',
                  largeWidth && 'is-fullwidth',
                )}
                role="dialog"
                aria-labelledby={titleId}
                style={{ opacity: 0 }}
                {...(isForm && onConfirm
                  ? {
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onSubmit: async (e) => {
                        e.preventDefault();

                        if (submittingForm) return;

                        setSubmittingForm(true);
                        try {
                          await onConfirm(
                            parseForm(e.target as HTMLFormElement) as IsForm extends true
                              ? Record<string, unknown>
                              : null,
                          );
                        } catch {}
                        setSubmittingForm(false);
                      },
                    }
                  : {})}
              >
                {header || (onClose && fullScreen) ? (
                  <header
                    className={clsx('modal-card-head', !header && 'has-background-white')}
                    id={titleId}
                  >
                    <div className="modal-card-head-content">
                      <div className="modal-card-title is-size-5 tw-font-secondary tw-font-bold">
                        {header}
                      </div>

                      {onClose && fullScreen ? (
                        <button
                          type="button"
                          className="delete"
                          aria-label="Close dialog"
                          onClick={onClose}
                        />
                      ) : null}
                    </div>
                  </header>
                ) : null}
                <div
                  className="modal-card-body"
                  ref={lockScrollRef}
                  style={{ backgroundColor: '#BFBDA3' }}
                >
                  {children}
                </div>
                {footer !== null ? (
                  <footer className="modal-card-foot px-6" style={{ backgroundColor: '#BFBDA3' }}>
                    {footer || (
                      <div className={clsx('flex flex-col justify-center', buttonsClass)}>
                        {onConfirm && labels.confirm && (
                          <Button
                            type={isForm ? 'submit' : 'button'}
                            className="tw-w-full tw-my-1"
                            isLoading={loading}
                            disabled={loading || confirmDisabled || submittingForm}
                            onClick={
                              isForm
                                ? undefined
                                : () =>
                                    onConfirm?.(
                                      null as IsForm extends true ? Record<string, unknown> : null,
                                    )
                            }
                          >
                            {labels.confirm}
                          </Button>
                        )}
                        {onClose && labels.cancel && (
                          <Button
                            type="button"
                            variant="transparent"
                            className={clsx('button tw-w-full tw-my-1')}
                            onClick={onClose}
                            disabled={submittingForm}
                          >
                            {labels.cancel}
                          </Button>
                        )}
                      </div>
                    )}
                  </footer>
                ) : null}
              </Card>

              {onClose && !fullScreen ? (
                <button
                  type="button"
                  className="modal-close is-large"
                  aria-label="Close dialog"
                  onClick={onClose}
                />
              ) : null}
            </FocusLock>,
            document.body,
          )}

      <style jsx global>{`
        $transition: 300ms ease-in-out;

        .modal {
          padding: 20px;

          &--fullscreen {
            padding: 0;

            .modal-card {
              flex: 1;
              width: 100%;
              flex-basis: 100%;
              max-width: none !important;
              max-height: none;
              margin: 0;

              & > * {
                border-radius: 0;
              }
            }
          }
        }

        .modal-background {
          transition: opacity $transition;

          opacity: 0;
          &.fade-in {
            opacity: 1;
          }
        }

        .modal-card {
          transition: opacity $transition, transform $transition;

          min-width: 260px;

          &.is-fullwidth {
            width: 100%;
          }

          opacity: 0;
          transform: translateY(30px);
          &.fade-in {
            opacity: 1 !important;
            transform: none;
          }
        }
      `}</style>

      <style jsx>{`
        .modal-card-head {
          display: block;
          padding: 0;
        }
        .modal-card-foot {
          display: block;
          padding: 16px 20px;
        }

        .modal-card-head-content {
          padding: 16px 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-shrink: 0;
          position: relative;
          background: #565940;
        }

        @media (min-width: 768px) {
          .modal-card {
            max-width: 720px;
          }
        }
      `}</style>
    </>
  );
};

export default Modal;
