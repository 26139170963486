import { cn } from 'lib/utils/cn';
import { forwardRef } from 'react';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: ButtonVariant;
  isLoading?: boolean;
};

const buttonVariants = {
  standard: 'tw-bg-terra tw-text-off-white tw-border-none',
  outlined: 'tw-border-terra tw-text-terra hover:tw-bg-terra hover:tw-text-off-white',
  outlinedAlt: 'tw-border-sage tw-text-ebony hover:tw-bg-ebony hover:tw-text-off-white',
  transparent: 'tw-border-none tw-bg-transparent tw-text-ebony',
  neutral: 'tw-bg-off-white tw-text-ebony tw-border-none',
  custom: 'tw-border-none',
};

type ButtonVariant = keyof typeof buttonVariants;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = 'standard', className, children, isLoading, ...props }, ref) => {
    return (
      <button
        className={cn(
          'tw-my-3 tw-py-2 tw-px-5 tw-rounded font-sans-serif tw-border tw-transition tw-duration-300',
          props.disabled && 'tw-cursor-not-allowed tw-grayscale',
          variant !== 'transparent' && 'hover:tw-shadow-md',
          buttonVariants[variant],
          className,
        )}
        type="button"
        ref={ref}
        {...props}
      >
        {isLoading ? (
          <div className="tw-flex tw-items-center tw-justify-center">
            <div className="tw-w-6 tw-h-6 tw-rounded-full tw-border-2 tw-border-white tw-border-b-transparent tw-animate-spin"></div>
          </div>
        ) : (
          children
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
