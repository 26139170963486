export default {
  name: 'Wanderglade',
  moto: 'Serene escapes for the modern traveler',
  description:
    'Discover unique cabin getaways with Wanderglade. Plan your perfect nature escape with curated stays and a trip planner.',
  theme_color: '#F2E0C9',
  background_color: '#FFFFFF',
  icons: [
    {
      src: '/static/ic/solid-192x192.png',
      sizes: '192x192',
      type: 'image/png',
    },
    {
      src: '/static/ic/solid-512x512.png',
      sizes: '512x512',
      type: 'image/png',
    },
  ],
  start_url: '/',
  keywords: [
    'wanderglade',
    'unique stays',
    'cabin rentals',
    'glamping',
    'nature retreats',
    'peaceful getaways',
    'serene accommodations',
    'verified listings',
    'curated escapes',
    'tranquil travel',
  ],
};
