import { Capacitor } from '@capacitor/core';

export const IS_SSR = typeof window === 'undefined';

export const RELEASE_ID = process.env.RELEASE_ID || undefined;

export const DEPLOY_ENV = (process.env.DEPLOY_ENV || process.env.NODE_ENV) as
  | 'development'
  | 'test'
  | 'staging'
  | 'preview'
  | 'beta'
  | 'production';
export const IS_DEV_ENV = DEPLOY_ENV === 'development';
export const IS_TEST_ENV = DEPLOY_ENV === 'test';
export const IS_PREVIEW_ENV = DEPLOY_ENV === 'preview';
export const IS_STAGING_ENV = DEPLOY_ENV === 'staging';
export const IS_BETA_ENV = DEPLOY_ENV === 'beta';
export const IS_PROD_ENV = DEPLOY_ENV === 'production';

export const NEXT_BUILD_ID = process.env.NEXT_BUILD_ID!;

export const IS_DEPLOYED = !!process.env.IS_DEPLOYED;

export const IS_FUNCTIONS = !!process.env.IS_FUNCTIONS;

export const USE_LOCAL_DB = !!process.env.USE_LOCAL_DB;

export const MOBILE_APP = process.env.BUILD_VARIANT === 'mobile_app_frontend';

/** `MOBILE_APP && PLATFORM === 'web'` can be true if running `yarn mobile:dev` and opening a web browser */
export const PLATFORM = (Capacitor?.getPlatform() || 'web') as 'android' | 'ios' | 'web';

// For non-mobileapp, the following 3 vars will be the same
export const HOST = process.env.HOST_URL!;
export const UNIQUE_BUILD_HOST = process.env.UNIQUE_BUILD_HOST_URL || HOST;
export const MOBILE_DEV_HOST = process.env.MOBILE_DEV_HOST_URL || null;

export const PROJECT_ID = process.env.PROJECT_ID!;
export const IS_PROD_DATA = PROJECT_ID === 'wanderglade-db';

export const FIREBASE_CONFIG = IS_PROD_DATA
  ? {
      apiKey: 'AIzaSyBb1xJYzImGIwpXQ-boaXYyJqi2H50Csh4',
      authDomain: 'wanderglade-db.firebaseapp.com',
      projectId: 'wanderglade-db',
      storageBucket: 'wanderglade-db.appspot.com',
      messagingSenderId: '122608812513',
      appId: '1:122608812513:web:fe889d57afcf7d193352a0',
    }
  : PROJECT_ID === 'wanderglade-dev'
  ? {
      apiKey: 'AIzaSyBlNjHolmSs85zyh8FIT5pjqv15A9SmcP8',
      authDomain: 'wanderglade-dev.firebaseapp.com',
      projectId: 'wanderglade-dev',
      storageBucket: 'wanderglade-dev.appspot.com',
      messagingSenderId: '633628692472',
      appId: '1:633628692472:web:0926db953d6ffd9b6f500d',
      measurementId: 'G-0KJXNPSNWH',
    }
  : {
      projectId: PROJECT_ID,
      apiKey: 'dne',
      authDomain: 'localhost:9099',
    };

export const PUBLIC_VAPID_KEY = IS_PROD_DATA
  ? 'BBfAFM549P4WMTRWPcuwYEg2IEDaGI8V284E9CvXP29P--GV3SRTYgDxQHd94VL2LaExgMXPleKs9K9VMFMZgU8'
  : 'BIld-J6ORI7bFZlkxvaqz46fudcS_gsHl9CrEYq7F6gGmwRsut3JvW3zisBPoXHSeK4uKwhYES2MgSRPXb2lY94';

export const HOTJAR_ID = '1800610';
export const GTAG_ID = 'AW-435133547';
