import * as _ from 'lodash-es';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/messaging';
import 'firebase/compat/analytics';
import 'firebase/compat/functions';

import { FIREBASE_CONFIG, USE_LOCAL_DB } from 'lib/env';
import { BUCKET_IDS } from 'lib/constants/db';

import { buildCollectionHelpers } from './utils';

/**
 *
 * !!! DO NOT IMPORT THIS FILE DIRECTLY !!!
 * instead, import `browserDb` from `lib/db/shared`
 *
 */

const app = firebase.apps[0] || firebase.initializeApp(FIREBASE_CONFIG);

const auth = app.auth();
const getUser = () => auth.currentUser;

const firestore = app.firestore();
const functions = app.functions();

if (USE_LOCAL_DB) {
  // @ts-expect-error disableWarnings is un-typed
  auth.useEmulator('http://localhost:9099', { disableWarnings: true });
  firestore.settings({ host: 'localhost:8080', ssl: false });
  // firebase.storage().useEmulator('localhost', 9199); TODO: blocked by https://github.com/firebase/firebase-tools/issues/3390
}

// TODO: maybe reenable this one day. especially on react-native
// if (!IS_SSR && enablePersistence && !IS_DEV_ENV) {
//   try {
//     await firestore.enablePersistence({
//       synchronizeTabs: true,
//     });
//   } catch (err) {
//     if (err.code === 'failed-precondition')
//       console.warn('Failed to initialize caching because multiple sessions are open');
//     else trackError(err);
//   }
// }

export const C = buildCollectionHelpers(firestore);

const batch = () => firestore.batch();

const buckets = _.mapValues(BUCKET_IDS, (bucketName) => app.storage(`gs://${bucketName}`).ref());

// Helper function to create an HTTPS request to call a Firebase HTTP function
const callHttpFunction = (functionName: string, data: any): Promise<any> => {
  console.log('Calling function:', functionName, 'with data:', data);
  const callable = functions.httpsCallable(functionName, { timeout: 390000 });
  return callable(data)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error('Error calling function:', error);
      throw error;
    });
};

export default {
  firebase,
  Firestore: firebase.firestore,
  FieldValue: firebase.firestore.FieldValue,
  FieldPath: firebase.firestore.FieldPath,
  Auth: firebase.auth,
  app,
  auth,
  getUser,
  firestore,
  C,
  batch,
  buckets,
  callHttpFunction,
};
