import Link from 'components/Link';
import { signOut } from 'lib/auth';
import { useAuthUser } from 'lib/hooks/auth';
import Router from 'lib/router';

import { ListingsSearchBarWithFilters } from 'components/ListingsSearchBar';

import BaseNav from './Base';
import { AccountItem } from './CTANav';

const logout = () => signOut().then(() => Router.push('/'));

export const NotifDot = ({ style }) => {
  return <span className="navbar-item__notif-dot" aria-label="Action required" style={style} />;
};

const NavbarEnd = ({ isLight }) => {
  const authUser = useAuthUser();
  // const { messageCount, guestNotifCount, hostNotifCount } = useNotifications();

  if (authUser)
    return (
      <>
        {/* <NavLink className="navbar-item" href="/host" exact>
          Host
          {hostNotifCount > 0 && <NotifDot />}
        </NavLink>
        <NavLink className="navbar-item" href="/trips">
          Trips
          {guestNotifCount > 0 && <NotifDot />}
        </NavLink>
        <NavLink className="navbar-item" href="/chats">
          Messages
          {messageCount > 0 && <NotifDot />}
        </NavLink> */}
        <AccountItem userId={authUser.uid} />
      </>
    );

  return (
    <>
      <span className="navbar-item">
        <Link
          href="/host"
          className="tw-inline-block tw-font-sans-serif tw-bg-transparent tw-border tw-border-terra tw-text-terra tw-font-medium tw-py-2 tw-px-4 tw-rounded tw-transition-colors tw-duration-300  hover:tw-bg-terra hover:tw-text-white"
        >
          Get Started
        </Link>
      </span>
    </>
  );
};

const MainNav = (props) => (
  <BaseNav
    navbarBrand={
      props.hasSearchBar && !props.isMobile ? (
        <span className="navbar-item" style={{ flexShrink: 1 }}>
          <ListingsSearchBarWithFilters noLocationSearch />
        </span>
      ) : undefined
    }
    navbarEnd={NavbarEnd}
    withUpstateNY={props.hasSearchBar}
    {...props}
  />
);

export default MainNav;
