import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as z from 'zod';

import clsx from 'clsx';

import {
  PLATFORM_AUTH_PROVIDERS,
  authenticateUser,
  getAuthStatusForEmail,
  getUser,
  sendResetPasswordEmail,
  sendWelcomeEmail,
  signOut,
} from 'lib/auth';
import { createUser, getPrivateProfile } from 'lib/db';
import { trackError } from 'lib/tracking';

import Link from 'components/Link';
import { prompts, toasts } from 'components/alert';
import { LoginProviderButton } from 'components/form/LoginProviderButton';

import { isValidEmail } from 'lib/utils/string';

import useCookie from 'lib/hooks/useCookie';

import Button from './Button';
import Field from './Field';

export const NUMBER_OF_EARLY_REGISTRANTS = 100;

type RegisterInputs = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
};

const signInSchema = z
  .object({
    email: z.string().nonempty('Required').email(),
    password: z.string().nonempty('Required'),
  })
  .nonstrict();

const signUpSchema = z
  .object({
    first_name: z.string().nonempty('Required'),
    last_name: z.string().nonempty('Required'),
    email: z.string().nonempty('Required').email(),
    password: z.string().min(8),
    confirm_password: z.string(),
  })
  .refine((vals) => vals.password === vals.confirm_password, {
    message: "Passwords don't match",
    path: ['confirm_password'],
  });

const LoginForm = ({
  onLoggedIn,
  onClose,
  isModal,
  fromPath,
  referrerName,
  isLogin = false,
}: {
  onLoggedIn: (profile: any) => void;
  onClose?: () => void;
  isModal?: boolean;
  fromPath?: string;
  referrerName?: string;
  isLogin?: boolean;
}) => {
  const [loading, setLoading] = useState(false);

  const [isLoginPage, setIsLoginPage] = useState(isLogin);

  const form = useForm<RegisterInputs>({
    mode: 'onBlur',
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirm_password: '',
    },
    resolver: useMemo(() => zodResolver(isLoginPage ? signInSchema : signUpSchema), [isLoginPage]),
  });

  const [reffererId] = useCookie('referrerId');

  const handleAuthenticateSuccess = async (
    signInData: any,
    registerFormData: RegisterInputs,
    isNewUserWithPassword = false,
  ) => {
    const authUser = getUser();

    if (authUser) {
      const privateProfile = await getPrivateProfile(authUser.uid, {
        fail: false,
      });

      if (!privateProfile) {
        await createUser({
          firstName: registerFormData.first_name,
          lastName: registerFormData.last_name,
          referrerId: reffererId,
        });
      }

      signInData = { ...signInData, privateProfile };

      if (!isNewUserWithPassword) await onLoggedIn?.(signInData);

      return signInData;
    }
  };

  const loginWithProvider = async (providerId: typeof PLATFORM_AUTH_PROVIDERS[number]['key']) => {
    const values = form.getValues();

    try {
      const signInData = await authenticateUser({
        type: 'provider_popup',
        providerId,
      });

      await handleAuthenticateSuccess(signInData, values);
    } catch (err) {
      toasts.error(err);
      trackError(err);
    }
  };

  const onSubmit: SubmitHandler<RegisterInputs> = async (data) => {
    console.log('submitting');
    const { email, password } = data;
    setLoading(true);

    try {
      const signInData = await authenticateUser({
        type: 'email_password',
        isNewUser: !isLoginPage,
        email,
        password,
      });

      await handleAuthenticateSuccess(signInData, data, signInData.isNewUser);

      if (signInData.isNewUser && signInData.uid) {
        await signOut();
        await sendWelcomeEmail(signInData.uid, fromPath);

        form.reset();

        prompts.alert(
          <div className="flex flex-col items-center justify-center" style={{ color: '#565940' }}>
            <h3 className="is-size-3 tw-font-bold has-text-centered">Awesome!</h3>
            <p className="has-text-centered my-5 font-sans-serif">
              You should receive a verification email in the email inbox for{' '}
              <strong>{email}</strong>
            </p>
            <p className="has-text-centered font-sans-serif">
              Please click the link in that email to finish signing up.
            </p>
          </div>,
        );
        onClose?.();
      }
    } catch (err) {
      toasts.error(err);
      trackError(err);
    }
    setLoading(false);
  };

  const resetPassword = async () => {
    let email = form.getValues('email');

    let firstAttempt = true;
    do {
      const res = await prompts.prompt(
        <p style={{ marginBottom: '1rem' }}>
          Please {!firstAttempt ? 'enter a valid' : email ? 'confirm your' : 'enter your'} email
          address
        </p>,
        {
          fields: [
            {
              element: (
                <input className="input" type="email" name="email" required defaultValue={email} />
              ),
            },
          ],
        },
      );

      if (res == null) return; // cancel

      email = res.email as string;

      firstAttempt = false;
    } while (!isValidEmail(email));

    setLoading(true);

    const authStatus = await getAuthStatusForEmail(email);

    if (!authStatus.exists) {
      toasts.error('An account with this email does not exist');
      setLoading(false);
      return;
    }

    try {
      await sendResetPasswordEmail(email, false);
    } catch (err) {
      trackError(err, 'send reset-password email', { sent_to_email: email });
      toasts.error(err);
      setLoading(false);
      return;
    }

    setLoading(false);

    await prompts.alert(
      <>
        <p className="is-size-5">
          You should receive a password reset email in the email inbox for <strong>{email}</strong>
        </p>
        <br />
        <p>Please click the link in that email to setup a new password.</p>
      </>,
    );
  };

  if (isModal) {
    return (
      <div className="md:tw-w-[512px]">
        <h4
          className="tw-font-bold tw-font-secondary tw-text-center is-size-4 mb-6"
          style={{ color: '#565940' }}
        >
          {isLoginPage ? (
            'Login to Wanderglade'
          ) : referrerName ? (
            <>
              Sign up with{' '}
              <span className="tw-text-brown tw-font-black">{referrerName}&apos;s</span> referral
            </>
          ) : (
            'Sign up for Wanderglade'
          )}
        </h4>
        <div className="columns">
          {PLATFORM_AUTH_PROVIDERS.map((provider) => (
            <div key={provider.key} className="column">
              <LoginProviderButton onClick={loginWithProvider} provider={provider} />
            </div>
          ))}
        </div>
        <p className="has-text-centered my-3" style={{ color: '#858476' }}>
          or
        </p>
        <form className="flex flex-col font-secondary" onSubmit={form.handleSubmit(onSubmit)}>
          {!isLoginPage && (
            <>
              <Field
                name="first_name"
                control={form.control}
                label="First Name"
                placeholder="E.g. Maria"
                className="w-full"
                showError={!!form.formState.errors.first_name}
                required
              />
              <Field
                name="last_name"
                control={form.control}
                label="Last Name"
                placeholder="E.g. Rossi"
                className="w-full"
                showError={!!form.formState.errors.last_name}
                required
              />
            </>
          )}
          <Field
            name="email"
            control={form.control}
            label="Email"
            placeholder="you@example.com"
            className="w-full"
            showError={!!form.formState.errors.email}
            required
          />
          <Field
            name="password"
            control={form.control}
            label="Password"
            placeholder={isLoginPage ? 'Password' : 'Create a password'}
            className="w-full"
            showError={!!form.formState.errors.password}
            required
            type="password"
          />
          {!isLoginPage && (
            <Field
              name="confirm_password"
              control={form.control}
              label="Confirm Password"
              placeholder="Re-enter your password"
              className="w-full"
              showError={!!form.formState.errors.confirm_password}
              required
              type="password"
            />
          )}
          {isLoginPage ? (
            <p>
              <button
                type="button"
                className="is-size-7 button-link grey-link font-sans-serif"
                onClick={resetPassword}
              >
                I forgot my password
              </button>
            </p>
          ) : (
            <p style={{ color: '#565940' }}>
              By signing up with Wanderglade, you agree to our{' '}
              <Link href="/terms" className="tw-font-bold">
                Terms
              </Link>{' '}
              and{' '}
              <Link href="/privacy" className="tw-font-bold">
                Privacy Policy
              </Link>
              .
            </p>
          )}

          <Button type="submit" isLoading={loading} disabled={loading}>
            {isLoginPage ? 'Sign in' : 'Sign up'}
          </Button>
        </form>
        <div className="flex items-center justify-center" style={{ color: '#565940' }}>
          {isLoginPage ? (
            <div className="flex tw-gap-1">
              <p>Don&apos;t have an account yet?</p>
              <button
                type="button"
                className="tw-font-bold font-sans-serif"
                onClick={() => setIsLoginPage(false)}
              >
                Sign up
              </button>
            </div>
          ) : (
            <div className="flex tw-gap-1">
              <p>Already a member?</p>
              <button
                type="button"
                className="tw-font-bold font-sans-serif"
                onClick={() => setIsLoginPage(true)}
              >
                Sign in
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <p
        className="font-secondary has-text-centered has-text-weight-bold mb-5"
        style={{ color: '#F2E0C9' }}
      >
        Signup with
      </p>
      <div className="columns">
        {PLATFORM_AUTH_PROVIDERS.map((provider) => (
          <div key={provider.key} className="column">
            <LoginProviderButton onClick={loginWithProvider} provider={provider} />
          </div>
        ))}
      </div>
      <p className="has-text-centered my-3" style={{ color: '#858476' }}>
        or
      </p>
      <form className="flex flex-col font-secondary" onSubmit={form.handleSubmit(onSubmit)}>
        <Field
          name="first_name"
          control={form.control}
          label="First Name"
          placeholder="E.g. Maria"
          className="w-full"
          showError={!!form.formState.errors.first_name}
          required
        />
        <Field
          name="last_name"
          control={form.control}
          label="Last Name"
          placeholder="E.g. Rossi"
          className="w-full"
          showError={!!form.formState.errors.last_name}
          required
        />
        <Field
          name="email"
          control={form.control}
          label="Email"
          placeholder="you@example.com"
          className="w-full"
          showError={!!form.formState.errors.email}
          required
        />
        <Field
          name="password"
          control={form.control}
          label="Password"
          placeholder="Create a password"
          className="w-full"
          showError={!!form.formState.errors.password}
          required
          type="password"
        />
        <Field
          name="confirm_password"
          control={form.control}
          label="Confirm Password"
          placeholder="Re-enter your password"
          className="w-full"
          showError={!!form.formState.errors.confirm_password}
          required
          type="password"
        />
        <p style={{ color: '#FFF7EC' }}>
          By signing up with Wanderglade, you agree to our{' '}
          <Link href="/terms" style={{ color: '#CBB89B' }}>
            Terms
          </Link>{' '}
          and{' '}
          <Link href="/privacy" style={{ color: '#CBB89B' }}>
            Privacy Policy
          </Link>
          .
        </p>
        <button
          type="submit"
          className={clsx(
            'button is-link font-sans-serif mt-6 flex items-center justify-center',
            loading && 'is-loading',
          )}
          style={{ backgroundColor: '#E07A5F' }}
          disabled={loading}
        >
          Sign up
        </button>
      </form>
      <style jsx>
        {`
          .fa-spin {
            animation-duration: 1.5s;
          }
        `}
      </style>
    </>
  );
};

export { LoginForm };
