import Head from 'next/head';
import Script from 'next/script';
import { useEffect, useMemo } from 'react';

import meta from 'config/meta.mjs';
import { authenticateUser, getUser, initAuth, waitForAuthTokenSync, waitForLogin } from 'lib/auth';
import { OTP_QUERY_KEY } from 'lib/constants/auth';
import { GlobalContextProvider } from 'lib/context';
import { setFcmToken } from 'lib/db';
import { GTAG_ID, IS_PROD_DATA, MOBILE_APP } from 'lib/env';
import { fetchPageProps } from 'lib/fetchPageProps';
import { QueryProvider } from 'lib/hooks/query';
import { useRouterEvent } from 'lib/hooks/router';
import usePropsState from 'lib/hooks/usePropsState';
import { initPushNotifications } from 'lib/pushNotifications';
import Router from 'lib/router';
import { getDeviceUUID, trackError, trackEvent, trackPageView } from 'lib/tracking';
import { UNAUTHORIZED } from 'lib/utils/errors';
import propsSerializer from 'lib/utils/propsSerializer';
import { addUrlQuery, relativeUrl } from 'lib/utils/url';

import Layout from 'components/Layout';
import NProgress from 'components/NProgress';
import { ResponsiveProvider } from 'components/Responsive';
import { Alerts, toasts } from 'components/alert';
import { requireLogin } from 'components/form/LoginPrompt';
import { LoadingCar } from 'components/landing/LandingImages';
import MetaGraph from 'components/social/MetaGraph';

import ErrorPage from './_error';

import { AppProps } from 'next/app';
import 'styles/global.scss';

initAuth(); // make sure we load this ASAP

const ogPreview = {
  src: '/static/wanderglade-preview.jpg',
  width: 2060,
  height: 1732,
};

// const videoPreview = {
//   src: '/static/landing/hero-video.mp4',
//   width: 4096,
//   height: 2160,
//   type: 'video/mp4',
// };

const loadLogin = async ({ url, query: { [OTP_QUERY_KEY]: otpToken } }: any) => {
  if (otpToken) {
    try {
      await authenticateUser({
        type: 'login_token',
        loginToken: otpToken,
      });

      return true;
    } catch (err) {
      trackError(err, 'sign in with otp token failed', { url });
    }
  } else {
    // wait for initial authentication to load and server-side cookie to be updated
    await waitForAuthTokenSync();
    if (getUser()) {
      return true;
    }
  }

  return false;
};

const UnauthenticatedLoader = () => {
  useEffect(() => {
    (async () => {
      const cleanUrl = addUrlQuery(Router.asPath, {
        apiKey: null,
        oobCode: null,
        mode: null,
        lang: null,
        [OTP_QUERY_KEY]: null,
      });

      const authenticated = await loadLogin({ url: Router.asPath, query: Router.query });

      if (authenticated) {
        // reload page, and remove email-link query params from url
        Router.replace(cleanUrl);
      } else {
        Router.pushRoute('index');
        if (!(await requireLogin(cleanUrl, true))) return;
        Router.push(cleanUrl);
      }
    })();
  }, []);

  return (
    <Layout navbar={null} footer={null} mobileReturnTo={false}>
      <section className="section">
        <div className="container has-text-centered">
          <h1 className="tw-text-ebon is-size-2">Loading...</h1>
          <div className="flex items-center justify-center">
            <LoadingCar style={{ maxWidth: 400 }} className="" />
          </div>
        </div>
      </section>
    </Layout>
  );
};

const usePushNotifications = () => {
  // this is run once when the page loads:
  useEffect(() => {
    return initPushNotifications({
      onUpdateFcmToken: (token) => {
        setFcmToken(token);
      },
      onActiveDeviceNotification: ({ title, body: _body, link, notification_id }) => {
        trackEvent('active_device_notification', { title, link, notification_id });

        link = relativeUrl(link ?? '');
        if (link === '/') link = null;

        // TODO: also show `body`?
        toasts.info(title, {
          link,
        });
      },
    });
  }, []); // don't add deps here!
};

let trackedInitial = false;
const usePageTracking = () => {
  useEffect(() => {
    const initialUrl = Router.asPath;

    Promise.all([getDeviceUUID(), waitForLogin()]).then(() => {
      if (trackedInitial) return;
      trackedInitial = true;
      trackPageView(initialUrl, true);
    });
  }, []);

  useRouterEvent('routeChangeComplete', (url, { shallow }) => {
    if (trackedInitial && !shallow) {
      trackPageView(url);
    }
  });
};

const App = ({
  Component,
  pageProps: initPageProps,
  err,
  router,
}: AppProps & { err?: unknown }) => {
  usePageTracking();

  usePushNotifications();

  const [serializedPageProps, setSerializedPageProps] = usePropsState(initPageProps);

  const reloadProps = async () => {
    const asPath = Router.asPath;

    const { pageProps } = await fetchPageProps(asPath);

    // skip if the path has changed
    if (pageProps && Router.asPath === asPath) {
      setSerializedPageProps(pageProps);
    }
  };

  const pageProps = useMemo(
    () => propsSerializer.deserialize(serializedPageProps),
    [serializedPageProps],
  );

  if (err) pageProps.__trackError = err;

  const error = pageProps.__error || (MOBILE_APP ? err : null);
  const ssrWidth = pageProps.__ssrWidth;

  const content =
    (error?.code === UNAUTHORIZED && error.meta?.notLoggedIn) || router.query[OTP_QUERY_KEY] ? (
      <UnauthenticatedLoader />
    ) : error ? (
      <ErrorPage
        code={error.code}
        message={error.message}
        __trackError={undefined}
        errorReported={undefined}
      />
    ) : (
      <Component {...pageProps} />
    );

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="locale" content="en_US" />
        {MOBILE_APP ? (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=0, viewport-fit=cover"
          />
        ) : (
          <meta name="viewport" content="width=device-width,initial-scale=1" />
        )}
      </Head>
      {!MOBILE_APP && (
        <Head>
          <link rel="manifest" href="/manifest.json" />
          <meta name="theme-color" content={meta.theme_color} />
          <link rel="icon" type="image/png" href="/static/ic/clear-16x16.png" sizes="16x16" />
          <link rel="icon" type="image/png" href="/static/ic/clear-32x32.png" sizes="32x32" />
          <link rel="icon" type="image/png" href="/static/ic/solid-192x192.png" sizes="192x192" />
          <link
            rel="apple-touch-icon"
            href="/static/ic/apple-touch-icon-180x180.png"
            sizes="180x180"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="og:site_name" content={meta.name} />
          <meta name="application-name" content={meta.name} />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-title" content={meta.name} />
          <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
          <meta name="msapplication-navbutton-color" content={meta.theme_color} />
          <meta name="msapplication-starturl" content="/?utm_source=homescreen" />
          <link
            rel="search"
            type="application/opensearchdescription+xml"
            href="/opensearch.xml"
            title={meta.name}
          />
        </Head>
      )}
      {!MOBILE_APP && IS_PROD_DATA && GTAG_ID ? (
        <Script src={`https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`} />
      ) : null}
      <MetaGraph
        description={meta.description}
        title={`${meta.moto} | ${meta.name}`}
        keywords={meta.keywords}
        image={ogPreview}
        article={undefined}
        // video={videoPreview}
      />

      <GlobalContextProvider value={{ reloadProps }}>
        <ResponsiveProvider ssrWidth={ssrWidth}>
          <QueryProvider fallback={pageProps.__prefetchData}>
            <>
              {content}
              <Alerts />
              {!MOBILE_APP && <NProgress />}
            </>
          </QueryProvider>
        </ResponsiveProvider>
      </GlobalContextProvider>
    </>
  );
};

export default App;
