import type firebase from 'firebase/compat/app';

import type { CellProvider, CellServiceType } from 'lib/constants/cellular';
import type {
  AmenityKey,
  CancellationPolicyKey,
  GroundAngleType,
  ListingRuleKey,
  ListingTypeKey,
} from 'lib/constants/listings';
import type {
  NotificationCategory,
  NotificationMedium,
  NotificationType,
} from 'lib/constants/notifications';
import type { UserPermission } from 'lib/constants/userPermissions';
import type { VehicleFeatureKey, VehicleSizeKey } from 'lib/constants/vehicles';

export type DocumentReference<T> = firebase.firestore.DocumentReference<T>;
export type Timestamp = firebase.firestore.Timestamp;

// represents a single day, e.g. Jan 5, 2020, as the number of days since Unix Epoche
export type FDay = number;

export type WithId<Doc> = Doc & { id: string; parent_id?: string; parent_type?: string };

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export type DocId = string;
type StripeId = string;

export type PhotoMeta = {
  id: string;
  description: string;
  blurDataUrl?: string; // a base64 url of a 4x4 image
  fdrz?: true; // stands for "failed resize"
};

// TODO: use separate collection
// export type FcmTokenDoc = {
//   user_id: string | null;
//   token: string;
//   device_id: string;
//   created_at: Timestamp;
//   last_success_at: Timestamp | null;
// }

export type UserDoc = {
  email: string | null;
  phone: string | null;
  isLister: boolean;
  onboarded?: boolean;
  listingCount?: number;
  notifications: {
    [key in Exclude<NotificationCategory, 'newsletter'>]?: {
      [medium in NotificationMedium]?: boolean;
    };
  } & { newsletter?: boolean };
  fcm_token: string | null;
  mobile_fcm_token?: string | null; // TODO

  disabled_at?: Timestamp | null;

  created: Timestamp;
  created_at?: Timestamp; // TODO
  updated_at?: Timestamp; // TODO
};

export type ProfileDoc = {
  firstName: string;
  lastName: string;
  name: string;
  avatar: string | null;
  avatarId: string | null;
  fdrz?: true; // stands for "failed resize". It means the avatar image failed to resize
  bio?: string;
  location?: string;
  verified_email: boolean;
  verified_govid: boolean;
  verified_phone: boolean;
  created: Timestamp;
  created_at?: Timestamp; // TODO
  updated_at?: Timestamp; // TODO
  travel_goals?: string[];
  travel_preferences?: string[];
  interests_and_activities?: string[];
  work?: string;
};

export type Experience = {
  description: string;
  price: number;
  type: 'flat' | 'daily';
  id: DocId;
};

export type CellCoverages = {
  [provider in CellProvider]: {
    [service_type in CellServiceType]: number; // in range [0.0, 1.0]
  };
};

type HoursFloat = number; // in range [0.0, 24.0)

// TODO: migrate to number
export type almost_number = number | string; // also contains "-1", which means unlimited

export type ListingDoc = {
  owner: DocumentReference<UserDoc>;
  status: 'active' | 'inactive';
  /** these are approximations of the `geocoords` field on PrivateListingDoc */
  geocoords: { lat: number; lng: number };
  /** boost on search page. Set to 0 to hide it. default is 1 */
  search_boost?: number | null;
  /** boost featuredness on the home page + stays page. Set to 0 to hide it. default is 1 */
  front_pages_boost?: number | null;
  type: ListingTypeKey;
  title: string;
  description: string;
  photos: PhotoMeta[];
  checkIn: HoursFloat;
  checkOut: HoursFloat;
  amenities: { [amenity in AmenityKey]?: boolean };
  experiences?: Experience[];
  unavailable_days: FDay[];
  custom_price: CustomPrice[];
  cell_coverages?: CellCoverages;
  rules: ListingRuleKey[];
  custom_rules: string[];
  price: almost_number; // TODO: store as cents integer e.g. 500 is $5.00
  extra_guest_price: number;
  pet_price: number;
  size: VehicleSizeKey;
  angle?: GroundAngleType;
  street: string;
  city: string;
  zip_code: string;
  state: string;
  country: string;
  bookingNoticeLimit: almost_number;
  bookingNoticeLimitTime: HoursFloat;
  howFarCanGuestsBook: almost_number | null;
  nightsMin: almost_number;
  nightsMax: almost_number | null;
  cancellation_policy: CancellationPolicyKey;
  timezone: string;
  instant_booking: boolean;
  created: Timestamp;
  updated: Timestamp;
  new_listing_discount?: number;
  weekly_discount?: number;
  monthly_discount?: number;
  status_changed_at?: Timestamp;
  /**
   * - 'automation': when a booking expires
   * - 'host': deactivated by the host
   * - 'manual': deactivated by an admin
   */
  status_changed_by?: 'automation' | 'host' | 'manual';

  application_status: ApplicationStatus | null;
  basics: ListingBasics;

  bookings: WithId<BookingDoc>[];
};

export type CustomPrice = {
  price: number;
  days: FDay[];
};

export type ListingBasics = {
  bathrooms: number;
  half_bathrooms: number;
  beds: number;
  free_guests: number;
  max_guests: number;
};

export type PrivateListingDoc = {
  street: string;
  street_number: number | string;
  address: string;
  geocoords: { lat: number; lng: number };
  owner: DocumentReference<UserDoc>;
  checkin_instructions?: string;
  created: Timestamp;
  updated: Timestamp;
  application_accepted: boolean | null;
  application_reviewed_at: Timestamp | null;
  application_reviewed_by: DocId | null;
};

export type BookingDoc = {
  from: FDay;
  to: FDay;
  length_of_stay: number;
  booker: DocumentReference<UserDoc>;
  lister: DocumentReference<UserDoc>;
  status: BookingStatus;
  listing: Omit<WithId<ListingDoc>, 'unavailableDays'>;
  start_at: Timestamp;
  end_at: Timestamp;
  guest_count: number;
  guests: BookingGuests;
  experiences_selected?: Experience[];
  coupon?: DocId;
  // we save these to check what we told
  // the guest we charged them is also what
  // we charge them
  // TODO: backfill these numbers
  transaction_amount?: number;
  guest_payment_amount?: number;
  guest_fee_ratio?: number;
  host_fee_ratio?: number;

  guest_service_fee?: number;
  host_service_fee?: number;
  total_service_fee?: number;

  denial_reason?:
    | 'dates_unavailable'
    | 'listing_requirements_not_met'
    | 'listing_no_longer_available'
    | 'dates_are_too_close'
    | 'dates_are_too_far'
    | 'guest_missing_information'
    | 'other'
    | null;
  denial_reason_other?: string | null;

  // TODO: don't store this here
  host_phone?: string | null;

  grouped_booking_id?: string | null;

  guest_payment_method: StripeId | null;
  payment_intent_id: StripeId | null;

  guest_refund_id: StripeId | null;
  guest_refund_amount: number | null;

  host_transfer_id: StripeId | null;
  host_transfer_amount: number | null;
  transfer_reversal_id?: StripeId;

  expired_at: Timestamp | null;

  canceled_by: DocId | 'automation' | null; // a `users` doc id
  canceled_from_status: 'pending' | 'confirmed' | null;
  canceled_at: Timestamp | null;
  denied_at: Timestamp | null;
  confirmed_at: Timestamp | null;

  checkin_instructions?: string | null;

  created: Timestamp;
  updated: Timestamp;
};

export type BookingDocWithGuest = WithId<BookingDoc> & { guest: ProfileDoc };

export type BookingStatus =
  | 'pending'
  | 'confirmed'
  | 'completed'
  | 'denied'
  | 'canceled'
  | 'expired';

export type BookingGuests = {
  adults: number;
  children: number;
  infants: number;
  pets: number;
};

// NOTE: reviews are integers in range [0, 4]
export type ProfileReviewDoc = {
  publicReview: string | null;
  general: number | null;

  user_id: DocId;
  booking_id: DocId;
  guest_id: DocId;
  listing_id: DocId;

  created: Timestamp;
};

export type SavedListingDoc = {
  listing_id: DocId;
  user_id: DocId;
  created_at: Timestamp;
};

// NOTE: reviews are integers in range [0, 4]
export type ListingReviewDoc = {
  publicReview: string | null;
  outcome: number | null;
  general: number | null;

  communication: number | null;
  accuracy: number | null;
  location: number | null;
  checkin: number | null;

  user_id: DocId;
  booking_id: DocId;
  guest_id: DocId;
  listing_id: DocId;

  files: {
    id: DocId;
    type: 'image' | 'video';
  }[];

  created: Timestamp;
};

export type ChatDoc = {
  booking_id: DocId | null;
  listing_id: DocId | null;
  sender_id: DocId;
  recipient_id: DocId;
  created_at: Timestamp;

  // TODO: use separate table
  messages_last_read_ats?: {
    [key in DocId]: Timestamp;
  };
};

// export type ChatUserDoc = {
//   messages_last_read_at: Timestamp | null;
//   created_at: Timestamp;
// };

export type ChatMessageDoc = {
  user_id: DocId;
  content: string;
  files?: {
    id: string; // e.g. `<bucket>/chats/<chat_id>/<id>`
    // image dimensions
    width: number;
    height: number;
    // might support arbitrary file types in the future
  }[];
  source:
    | null
    | 'checkin_instructions'
    | 'booking_review_feedback'
    | 'intro_message'
    | 'listing_owner_message'
    | 'denial_message'
    | 'guest_payment_declined';

  created_at: Timestamp;
};

export type SupportInquiryDoc = {
  sender_email: string; // Email address of the person making the inquiry
  customer_user_id: DocId | null; // Could be null if the sender is not a registered user
  booking_id: DocId | null;
  listing_id: DocId | null;
  customer_name: string | null;
  created_at: Timestamp;
  messages_last_read_ats?: {
    [key in DocId]: Timestamp;
  };
  case_status?: 'open' | 'closed';
};

export type SupportInquiryMessageDoc = {
  // Could be null if the sender is not a registered user
  // Could also be their email address
  user_id: DocId | null;
  user_email: string | null;
  content: string;
  files?: {
    id: string;
    width: number;
    height: number;
  }[];
  source:
    | 'support_inquiry'
    | 'support_reply'
    | 'admin_dashboard'
    | 'email_reply'
    | 'email_outreach';
  created_at: Timestamp;
};

export type PhotographerProfileDoc = {
  id: DocId;
  user_id: DocId;
  over_18: boolean;
  location: {
    city: string;
    state: string;
  };
  has_camera: boolean;
  instagram_url: string;
  portfolio_url: string;
};

export type ReferralDoc = {
  user_id: DocId;
  referred_user_id: DocId;
  referral_bonus_usd: number;
  created_at: Timestamp;
};

export type SyncedCalendarDoc = {
  id: DocId;
  user_id: DocId;
  created: Timestamp;
  listing_id: string;
  calendar_name: string;
  calendar_link: string;
  unavailable_dates: FDay[];
  last_synced_at: Timestamp;
};

export type EarlyRegistrantsDoc = {
  user_id: DocId;
  created: Timestamp;
};

export enum ApplicationStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export type IdentityApplicationDoc = {
  user_id: DocId;
  created_at: Timestamp;
  application_status: ApplicationStatus;
};

export type ResidencyApplicationDoc = {
  user_id: DocId;
  listing_id: DocId;
  created_at: Timestamp;
  application_status: ApplicationStatus;
};

export type AirbnbInviteListingDoc = {
  airbnb_listing_url: string;
  listing_id?: string;
  price: string;
  hostname: string;
  title: string;
  description?: string;
  photos?: string[];
  amenities?: string[];
  basics: {
    guests?: number;
    beds?: number;
    bathrooms?: number;
  };
  // approximateLocation?: string | null;
  // Terlingua, Texas, United States
  geocoords?: { lat: number; lng: number };
  address?: string;
  street?: string;
  city?: string;
  zip_code?: string;
  state?: string;
  country?: string;
};

export type VRBOInviteListingDoc = {
  airbnb_listing_url: string;
  listing_id?: string;
  price: string;
  hostname: string;
  title: string;
  description?: string;
  photos?: string[];
  amenities?: string[];
  basics: {
    guests?: number;
    beds?: number;
    bathrooms?: number;
  };
  // approximateLocation?: string | null;
  // Terlingua, Texas, United States
  geocoords?: { lat: number; lng: number };
  address?: string;
  street?: string;
  city?: string;
  zip_code?: string;
  state?: string;
  country?: string;
};

export type LatestListing = {
  id: DocId;
  title: string;
  city: string;
  state: string;
  attribution: UserSourceAttributionDoc | null;
};

export type MetaDailyDoc = {
  count: number;
  daily: number;
  created_at: Timestamp;
};

export type MetaDataDoc = {
  count?: number;
  states?: Record<string, number | undefined>;
  latest?: LatestListing;
  latestDaily?: MetaDailyDoc;
  pending?: number;
  attribution?: UserSourceAttributionDoc | null;
  sources?: Record<string, number | undefined>;
  host_sources?: Record<string, number | undefined>;
};

export type MetaHistoryDoc = MetaDataDoc & {
  created_at: Timestamp;
  count: number;
};

export type NotificationDoc = {
  created_at: Timestamp;
  linkTo: string | null;
  user_id: string;
  read_at: Timestamp | null; // in-app notification was read
  mobile_push_read_at?: Timestamp | null; // mobile_push notification was read
  type: NotificationType;
  category: NotificationCategory;
  message: {
    title: string;
    body?: string;
  } | null;
  medium_statuses?: {
    [key in 'web_push' | 'mobile_push' | 'email' | 'sms']: 'sent' | 'failed' | null;
  };
};

export type EmailTrackerDoc = {
  sent_at: Timestamp;
  sg_message_id: string;
  email: string;
  user_id: DocId | null;
  opened_at: Timestamp | null;
  opens: number;
  clicked_at: Timestamp | null;
  clicks: number;
  type: string | null;
  category: string | null;
  notification_id?: DocId | null; // TODO backfill
};

export type AdminDoc = {
  email: string;
  name: string;
  admin_notifications?: boolean;
  slack_id?: string;
  permissions: UserPermission[];
};

export type AdminCommentDoc = {
  created_at: Timestamp;
  content: string;
  user_id: DocId;
  user_name: string | null;
};

export type UserSourceAttributionDoc = {
  source: string;
  other: string;
  user_id: DocId;
  created_at?: Timestamp;
};

export type ProductFeedbackDoc = {
  additional_feedback: string | null;
  recommend_rating: number | null;
  testimonial: string | null;
  user_id: DocId;
  is_host: boolean | null;
  created_at: Timestamp;
};

export type VehicleDoc = {
  year: string;
  model: string;
  plate: string;
  make: string;
  vehicle_features: { [feature in VehicleFeatureKey]?: boolean };
  length_ft?: number;

  deleted_at: null | Timestamp;
  created: Timestamp;
};

export type StripeCustomerDoc = {
  stripe_customer_id: StripeId;
  default_payment_method_id: StripeId | null;
  stripe_env: 'live' | 'test';

  created_at: Timestamp;
};

export type StripeAccountDoc = {
  stripe_account_id: StripeId;
  stripe_env: 'live' | 'test';

  created_at: Timestamp;
};

export type ShortLinkDoc = {
  url: string;
  source: string | null;
  created_at: Timestamp;
  clicks: number;
  clicked_at: Timestamp | null;
  notification_id: DocId | null;
};

export type InstallAttributionDoc = {
  referral_code: string;
  device_uuid: string;
  user_id: string | null;
  created_at: Timestamp;
};

export type CouponRestrictions = {
  // only applies to this listing
  listing: DocId | null;
  // only applies after a minimum
  // expressed in $ amount
  minimum: number | null;
  // only applies to a user
  user: DocId | null;
  // TODO: only applies to listings in a certain area
  // TODO: only applies to certain dates
};

export type CouponDoc = {
  code: string;
  created_by: DocId;
  created_at: Timestamp;
  expires_at: Timestamp;
  type: 'percentage' | 'dollars' | 'percentage_with_cap';
  percentage: number | null;
  dollars: number | null;
  restrictions: CouponRestrictions;
  active: boolean;
  used_count: number;
};

export type NewsletterFeatureAnnouncement = {
  feature_image: string;
  feature_name: string;
  feature_description: string;
};

export type NewsletterBlog = {
  title: string;
  intro: string;
  slug: string;
  img: string;
};

export type NewsletterFeaturedListing = {
  title?: string;
  location: string;
  image: string;
  id: DocId;
  align_img_right?: boolean;
};

export type NewsletterEvent = {
  title: string;
  location?: string;
  date: string;
  description: string;
  link?: string;
};

export type NewsletterStatus = 'draft' | 'ready' | 'sent' | 'archived';

export type NewsletterDoc = {
  created_at: Timestamp;
  updated_at: Timestamp;
  sent_at: Timestamp | null;
  created_by: DocId;
  subject: string;
  header_title: string;
  header_content: string;
  header_cta_link: string;
  header_cta_copy: string;
  new_features: NewsletterFeatureAnnouncement[];
  blogs: NewsletterBlog[];
  featured_listings: NewsletterFeaturedListing[];
  events?: NewsletterEvent[];
  sender_name: string;
  status: NewsletterStatus;
  name: string;
};

export type CostsDoc = {
  twilio: number | null;
  stripe: number | null;
  google: number | null;
  logflare: number | null;
  vercel: number | null;
  sendgrid: number | null;
};

export type MonthlyKPIsDoc = {
  start_at: Timestamp;
  end_at: Timestamp;
  active_host_count: number | null;
  active_users_count: number | null;
  revenue: number | null;
  active_listings_acquired_count: number | null;
  bookings_count: number | null;
  bookings_confirmed_count: number | null;
  booking_intents_count?: number | null;
  gmv: number | null;
  ios_installs_count: number | null;
  android_installs_count: number | null;
  inquiries_count: number | null;
  inquiries_seen_count: number | null;
  costs: CostsDoc;
};

export type HolidayProfileDoc = {
  favorites: Record<string, string>;
  choices: Record<string, string>;
  this_or_that: Record<`${string}__or__${string}`, string>;
  personal_note: string | null;
  address: {
    street: string;
    city: string;
    state: string;
    zipcode: string;
  };
  name?: string;
  auto_bio: string | null;
  created_at: Timestamp;
  disabled_at: Timestamp | null;

  matched_user_id?: DocId | null;
  package_sent_at?: Timestamp | null;
  package_provider?: string | null;
  package_tracking_number?: string | null;
};

export type AdminAction = {
  label: string;
  inline: boolean;
  link?: string;
  actio?: () => void;
};

export type ListingFeedbackActionItem = {
  label: string;
  summary: string;
};

export type ListingFeedbackDoc = {
  listing_id: DocId;
  score: number;
  overall: string;
  actionItems: ListingFeedbackActionItem[] | string[];
  host_id: DocId;
  created_at: Timestamp;
  sent: boolean;
};
