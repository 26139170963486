import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';

import useAtTop from 'lib/hooks/useAtTop';
import useLocalStorage from 'lib/hooks/useLocalStorage';

const useBodyClassName = (className: string, enabled: boolean) => {
  enabled = !!enabled;

  const classRef = useRef(className);
  useEffect(() => {
    document.body.classList.toggle(classRef.current, enabled);
  }, [enabled]);

  useEffect(
    () => () => {
      document.body.classList.remove(classRef.current);
    },
    [],
  );
};

const Banner = ({
  dismissKey,
  short = false,
  height,
  backgroundColor,
  showBelowTop = false,
  bottomBorder = false,
  className,
  style,
  dontAllowDismiss = false,
  children,
}: {
  dismissKey?: string;
  short?: boolean;
  height?: string;
  backgroundColor?: string;
  showBelowTop?: boolean;
  bottomBorder?: boolean;
  className?: string;
  style?: React.CSSProperties;
  dontAllowDismiss?: boolean;
  children: React.ReactNode;
}) => {
  const atTop = useAtTop(70);
  const [dismissed, setDismissed] = useLocalStorage(
    `wanderglade:dismissed-banner:${dismissKey}`,
    false,
  );

  const visible = atTop ? true : showBelowTop;

  useBodyClassName('with-banner', dontAllowDismiss ? true : !dismissed);

  if (dismissed && !dontAllowDismiss) return null;

  return (
    <>
      <div className={clsx('banner', !visible && 'banner--hidden', className)} style={style}>
        {children}
        {!!dismissKey && (
          <button
            type="button"
            className="delete"
            onClick={() => {
              setDismissed(true);
            }}
          >
            Dismiss
          </button>
        )}
      </div>

      <style jsx>{`
        @import 'styles/variables';

        .banner {
          background-color: ${backgroundColor || 'hsl(48, 100%, 67%)'};
          width: 100%;
          height: ${height || (short ? '50px' : '70px')};
          z-index: 31; // so tooltips show up over the navbar
          display: flex;
          align-items: center;
          text-align: center;
          ${bottomBorder ? 'border-bottom: 2px solid #d0d1cd;' : ''}

          &--hidden {
            order: 1;
            visibility: hidden;
          }

          a {
            text-decoration: underline;
          }

          .delete {
            margin: 0 0.5rem;
          }
        }
      `}</style>
    </>
  );
};

export default Banner;
