import clsx from 'clsx';

import { useState } from 'react';

import SocialIcon from 'components/social/SocialIcon';
import { PLATFORM_AUTH_PROVIDERS } from 'lib/auth';

interface LoginProviderButtonProps {
  provider: any;
  onClick: (providerId: typeof PLATFORM_AUTH_PROVIDERS[number]['key']) => void;
}

const LoginProviderButton = ({ provider, onClick }: LoginProviderButtonProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <button
        type="button"
        disabled={loading}
        className={clsx('button is-fullwidth has-text-weight-bold', loading && 'is-loading')}
        style={provider.buttonStyles}
        onClick={async () => {
          setLoading(true);
          await onClick(provider.key);
          setLoading(false);
        }}
      >
        <div className="flex justify-center w-full">
          <SocialIcon icon={provider.socialIcon} size={18} iconStyle={{ marginRight: 8 }} />
          <p className="font-sans-serif">{provider.name}</p>
        </div>
      </button>
      <style jsx>{`
        button {
          background-color: white; // default
          justify-content: flex-start;
          font-size: 0.8rem;
          height: 2.5rem;
          padding-left: 1rem;

          &:hover {
            filter: brightness(93%);
          }
        }
      `}</style>
    </>
  );
};

export { LoginProviderButton };
