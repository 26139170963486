import clsx from 'clsx';
import { useRouter } from 'next/router';
import { ChangeEvent, useCallback, useState } from 'react';

import LocationSearchInput from 'components/LocationSearchInput';
import { useIsMobile } from 'components/Responsive';
import { DatesArr, DatesRangePicker } from 'components/form/DatesRangePicker';
import { MOBILE_APP } from 'lib/env';
import { decodeListingsSearchLink, encodeListingsSearchLink } from 'lib/geo';
import Router from 'lib/router';
import { trackEvent } from 'lib/tracking';
import { getBoundsZoomLevel } from 'lib/utils/distance';

// HACK
const mapSize = () => {
  const el = document.querySelector<HTMLElement>('.listings-search-map');

  if (el) {
    return {
      width: el.offsetWidth,
      height: el.offsetHeight,
    };
  }

  return {
    width: window.innerWidth <= 769 ? window.innerWidth : window.innerWidth * (5 / 12) - 32,
    height: window.innerHeight - 150,
  };
};

// TODO: move to <ListingsSearchInput/>
type ListingsInputCbParams = {
  geocoords?: { lat: number; lng: number };
  address?: string;
  northEast?: { lat: number; lng: number };
  southWest?: { lat: number; lng: number };
  searchTerm?: string;
  originalSeachTerm?: string;
  notFoundResult?: boolean;
};

// just `dates` for now
type ExtraSearchParams = Pick<
  Parameters<typeof encodeListingsSearchLink>[0],
  'dates' | 'zoom' | 'radius' | 'guests'
>;

type PerformSearchParams = ListingsInputCbParams & {
  extraSearchParams?: ExtraSearchParams;
};

const performSearch = ({
  geocoords,
  address,
  northEast,
  southWest,
  searchTerm,
  originalSeachTerm,
  notFoundResult,
  extraSearchParams,
}: PerformSearchParams) => {
  const { lat, lng } = (geocoords as { lat: number; lng: number } | undefined) || {};

  trackEvent('search', {
    search_term: searchTerm,
    original_search_term: originalSeachTerm,
    lat,
    lng,
  });

  if (notFoundResult) {
    Router.push(encodeListingsSearchLink({ address: searchTerm, ...(extraSearchParams || {}) }));
  } else {
    const { width: mapWidth, height: mapHeight } = mapSize();

    const [zoom, radius] =
      northEast && southWest
        ? getBoundsZoomLevel({
            northEast,
            southWest,
            mapWidth,
            mapHeight,
          })
        : [undefined, undefined];

    Router.push(
      encodeListingsSearchLink({
        lat,
        lng,
        address,
        zoom,
        radius,
        ...(extraSearchParams || {}),
      }),
    );
  }
};

const ListingsSearchBar: React.FC<{
  className?: string;
  containerClassName?: string;
  iconColorClass?: string;
  placeholder?: string;
}> = ({ className, containerClassName, iconColorClass, placeholder = 'Find parking' }) => {
  const handleSelectGeo = useCallback((params: ListingsInputCbParams) => {
    performSearch({ ...params });
  }, []);

  const pageUrl = useRouter().asPath;

  const [initValue] = useState(
    () => decodeListingsSearchLink(pageUrl)?.parsedLocation || undefined,
  );

  const isMobile = useIsMobile();

  return (
    <>
      <LocationSearchInput
        onSelect={handleSelectGeo}
        searchType="geocode,establishment"
        // @ts-expect-error untyped
        icon="search"
        iconColorClass={iconColorClass}
        placeholder={placeholder}
        className={containerClassName}
        inputClassName={clsx('input is-medium', className)}
        initValue={initValue}
        allowNotFoundResult
        useShortDisplay
        showClearButton={isMobile || MOBILE_APP}
      />

      <style jsx global>{`
        @import 'styles/variables';

        input.ListingsSearchBar--mobile-header {
          border-radius: 100px;
          border: none;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
          height: 48px;
          padding-left: 48px !important;
          & ~ .icon {
            width: 48px !important;
            height: 48px !important;
          }
        }

        input.c-listing-search {
          border-width: 2px;
          transition: box-shadow 0.3s ease;
          box-shadow: 0 1px 2px rgba(22, 23, 26, 0.1);
          &:hover {
            box-shadow: 0 1px 6px 1px rgba(22, 23, 26, 0.1);
          }
        }
      `}</style>
    </>
  );
};

export default ListingsSearchBar;

export const ListingsSearchBarWithFilters = ({
  noLocationSearch = false,
}: {
  noLocationSearch: boolean;
}) => {
  const [dates, setDates] = useState<DatesArr>([null, null]);
  const [guests, setGuests] = useState(1);

  const [geoParams, setGeoParams] = useState<ListingsInputCbParams | null>(null);

  const pageUrl = useRouter().asPath;

  const [initValue] = useState(
    () => decodeListingsSearchLink(pageUrl)?.parsedLocation || undefined,
  );

  const handleGuestsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.currentTarget.value);

    if (value < 1) {
      setGuests(1);
    } else {
      setGuests(value);
    }
  };

  return (
    <form
      className="flex items-stretch"
      onSubmit={(e) => {
        e.preventDefault();

        performSearch({
          ...(geoParams || {}),
          extraSearchParams: {
            dates: dates[0] && dates[1] ? { start: dates[0], end: dates[1] } : undefined,
            guests,
          },
        });
      }}
    >
      {!noLocationSearch && (
        <LocationSearchInput
          onSelect={setGeoParams}
          // @ts-expect-error untyped
          onChange={(inputVal: string) => {
            if (!inputVal) setGeoParams(null);
          }}
          searchType="geocode,establishment"
          placeholder="Upstate New York"
          className="listings-search-with-filters"
          inputClassName={clsx('input is-medium pl-4 tw-font-sans-serif !tw-text-ebony')}
          initValue={initValue}
          allowNotFoundResult
          useShortDisplay
          isStandard
          disabled
        />
      )}

      <DatesRangePicker
        className="dates-bar tw-font-sans-serif tw-hidden md:tw-block"
        dates={dates}
        onDatesChange={(d) => setDates([d.start, d.end])}
        showClearDates
        leftRounded={noLocationSearch}
      />

      <div
        id="guests-input-container"
        className="control tw-bg-white tw-py-1 tw-pl-4 tw-border-y tw-border-[#dbdbdb]"
      >
        <div>
          <label
            className="label is-small !tw-text-gray tw-font-secondary !tw-mb-0 tw-cursor-pointer"
            htmlFor="guests-input"
          >
            Guests
          </label>
          <input
            id="guests-input"
            type="number"
            value={guests.toString()}
            onChange={handleGuestsChange}
            min={1}
            className="tw-cursor-pointer tw-outline-none tw-w-20 tw-font-sans-serif"
          />
        </div>
      </div>

      <button type="submit" className="button" aria-label="Search">
        <div className="md:tw-h-9 md:tw-w-9 md:tw-rounded-full md:tw-bg-terra md:tw-text-white tw-flex tw-items-center tw-justify-center">
          <span className={clsx('icon is-small')} aria-hidden>
            <i className={clsx('fas fa-search')} />
          </span>
        </div>
      </button>

      <style jsx>{`
        @import 'styles/variables';

        form {
          height: 52px;
          @include mobile {
            height: 40px;
          }

          & > :global(*) {
            height: 100%;
          }
        }

        :global(.dates-bar) {
          :global(.field),
          :global(.control),
          :global(.input) {
            height: 100%;
          }

          :global(.input) {
            border-radius: 0;
          }
        }

        button {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 999px;
          border-bottom-right-radius: 999px;
          border-left: none;
        }

        :global(.navbar-item) form {
          :global(.listings-search-with-filters) {
            max-width: 12rem;
          }

          opacity: 0;
          animation: opacity-fade-in 0.3s ease-in-out forwards;
        }
        @keyframes opacity-fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        :global(.listings-search-with-filters) {
          max-width: 24rem;

          @include mobile {
            flex: 1;
          }

          :global(.dropdown-trigger),
          :global(.input) {
            height: 100%;
          }

          :global(.input) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 999px;
            border-bottom-left-radius: 999px;
            border-right: none;

            font-size: 16px;

            &::placeholder {
              color: $grey-dark !important;
            }

            &:focus,
            &:active {
              border-color: transparent;
              background-color: white;
              // same as DatesRangePicker
              box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
            }
          }
        }

        #guests-input-container {
          @include mobile {
            display: none;
          }
        }

        // Remove spinner for input number
        /* Chrome, Safari, Edge, Opera */
        #guests-input::-webkit-outer-spin-button,
        #guests-input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }

        /* Firefox */
        #guests-input {
          -moz-appearance: textfield;
        }
      `}</style>
    </form>
  );
};
