import clsx from 'clsx';
import * as _ from 'lodash-es';
import { useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';

const LimitedTextInput = ({
  value = '',
  onChange,
  rules,
  minLength = rules?.minLength,
  maxLength = rules?.maxLength,
  className,
  isTextArea = false,
  error,
  charProgressMode = maxLength != null ? 'all' : null,
  autofocus,
  inputId,
  capitalize = false,
  ...rest
}) => {
  const Tag = isTextArea ? 'textarea' : 'input';
  const inputRef = useRef();

  useIsomorphicLayoutEffect(() => {
    if (autofocus) inputRef.current?.focus();
  }, [autofocus]);

  if (minLength != null && minLength <= 0) minLength = undefined;

  const aboveMax = maxLength != null && maxLength - value.length < 0;
  const belowMin = minLength != null && minLength - value.length > 0;
  const showCharProgress = charProgressMode === 'all' || (charProgressMode === 'error' && aboveMax);

  return (
    <>
      <Tag
        {...(isTextArea ? {} : { type: 'text' })}
        id={inputId}
        {...rest}
        className={clsx(isTextArea ? 'textarea' : 'input', error && 'is-danger', className)}
        minLength={minLength}
        onChange={(e) => onChange?.(capitalize ? _.capitalize(e.target.value) : e.target.value)}
        value={capitalize ? value.toUpperCase() : value}
        ref={inputRef}
      />
      {showCharProgress ? (
        <p className={clsx('has-text-right', aboveMax ? 'has-text-danger' : 'has-text-grey')}>
          <span className={clsx(belowMin && 'has-text-danger')}>{value.length}</span>/{maxLength}
        </p>
      ) : null}

      <style jsx>{`
        p {
          font-size: 14px;
          margin: 4px 4px 0 4px;
        }
      `}</style>
    </>
  );
};

export default LimitedTextInput;
