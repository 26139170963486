import { usePopper } from '@chakra-ui/popper';
import clsx from 'clsx';
import * as _ from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DayModifiers, DayPicker, SelectRangeEventHandler } from 'react-day-picker';
import { useClickAway } from 'react-use';

import usePropsState from 'lib/hooks/usePropsState';
import { makeIsDateAvailable } from 'lib/listing';
import { useSequentialId } from 'lib/utils/sequentialId';
import { timeToDate } from 'lib/utils/time';
import type { FDay, ListingDoc } from 'schemas/types';

import { useIsMobile } from 'components/Responsive';
import { FormatDate } from 'components/Time';

export enum RangeInputType {
  Start = 'Start',
  End = 'End',
}

const DateInput: React.FC<{
  name: string;
  value: Date | null;
  // onChange: (date: Date | null) => void;
  onFocus: (type: RangeInputType) => void;
  type: RangeInputType;
  label?: string;
  placeholder?: string;
  onClear?: () => void;
  getReferenceProps: any;
  focused?: boolean;
  leftRounded?: boolean;
}> = ({
  name,
  value,
  onFocus,
  focused,
  type,
  label,
  placeholder,
  onClear,
  getReferenceProps,
  leftRounded,
}) => {
  const id = `range-${type}-${useSequentialId()}`;
  return (
    <div
      className={clsx('control', value && 'has-icons-right', !leftRounded && 'tw-flex-1')}
      style={{
        minWidth: 120,
      }}
    >
      {/* HACK: this is used by `pages/admin/bookings/show.js` */}
      <input type="hidden" name={name} value={value?.toISOString().slice(0, 10) || ''} />

      {label ? (
        <label
          className={clsx(
            'label is-small !tw-text-gray tw-font-secondary',
            leftRounded && '!tw-left-10 tw-flex-1',
          )}
          htmlFor={id}
        >
          {label}
        </label>
      ) : null}
      <button
        id={id}
        type="button"
        aria-pressed={focused}
        className={clsx(
          'input is-medium !tw-text-ebony tw-font-sans-serif',
          leftRounded && '!tw-pl-10 !tw-rounded-l-full !tw-w-[140px]',
          label && 'with-label',
          value && 'filled',
          focused && 'focused',
        )}
        {...getReferenceProps({
          onClick: () => onFocus(type),
        })}
      >
        {value ? <FormatDate time={value} format="MMM d" /> : placeholder}
      </button>
      {onClear && value ? (
        <button
          type="button"
          aria-label="Clear start date"
          className="button-reset icon is-right"
          onClick={onClear}
        >
          <i className="fas fa-times-circle" />
        </button>
      ) : null}

      <style jsx>{`
        @import 'styles/variables';

        .icon.is-right {
          top: 50%;
          transform: translateY(-50%);
          color: $grey-light;
          pointer-events: all;
          border-radius: 50%;
          &:hover {
            color: $grey;
          }
        }

        .label {
          position: absolute;
          top: 6px;
          left: 12px;
          z-index: 5;
          pointer-events: none;
        }
        .input {
          cursor: pointer;
          box-shadow: none;
          color: $grey;
          font-size: 15px;
          height: 36px; // hack

          &.with-label {
            height: 52px;
            padding-top: 25px;
          }

          &:hover {
            background: #fafafa;
          }
          &.focused,
          &:focus,
          &:active {
            border-color: transparent;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
          }

          &.filled {
            color: $text;
          }
        }
      `}</style>
    </div>
  );
};

export type DatesArr = [Date | null, Date | null];

export const DatesRangePicker: React.FC<
  {
    syncedUnavailableDays?: number[];
    dates?: DatesArr;
    onDatesChange: (datesInfo: { start: Date | null; end: Date | null }) => void;
    style?: React.CSSProperties;
    className?: string;
    availabilityEnabled?: boolean;
    showClearDates?: boolean;
    focusedInputDefault?: RangeInputType | null;
    allowPastDates?: boolean;
    displayInline?: boolean;
    labels?: [start: string, end: string];
    noLabels?: boolean;
    leftRounded?: boolean;
    unavailableDays?: FDay[];
  } & Partial<
    Pick<ListingDoc, 'nightsMin' | 'nightsMax' | 'bookingNoticeLimit' | 'howFarCanGuestsBook'>
  >
> = ({
  dates: propsDates,
  onDatesChange,
  style = {},
  className,
  availabilityEnabled = false,
  unavailableDays,
  nightsMin,
  nightsMax,
  bookingNoticeLimit,
  howFarCanGuestsBook,
  syncedUnavailableDays,
  showClearDates = false,
  focusedInputDefault = null,
  allowPastDates = false,
  displayInline = false,
  labels = ['Check in', 'Check out'],
  noLabels = false,
  leftRounded = false,
}) => {
  const popper = usePopper({
    placement: 'bottom-start',
    strategy: 'fixed',
    // modifiers: [
    //   {
    //     name: 'preventOverflow',
    //     options: {
    //       padding: 8,
    //     },
    //   },
    // ],
    enabled: !displayInline,
  });

  const [[startDate, endDate], setStateDates] = usePropsState(
    useMemo(() => [timeToDate(propsDates?.[0]), timeToDate(propsDates?.[1] || null)], [propsDates]),
  );

  const latestOnChange = useRef(onDatesChange);
  latestOnChange.current = onDatesChange;
  const handleChange = useCallback(
    ([start, end]: DatesArr) => {
      if (!!start === !!end) latestOnChange.current?.({ start, end });
      setStateDates([start, end]);
    },
    [setStateDates],
  );

  const [focusedInput, setFocusedInput] = useState(focusedInputDefault);

  // focus END_DATE when we set a new START_DATE via clicking a calendar date
  const endDateRef = useRef(endDate);
  endDateRef.current = endDate;
  useEffect(() => {
    if (startDate && !endDateRef.current) setFocusedInput((prev) => prev ?? RangeInputType.End);
  }, [startDate]);

  // SCENARIOS:
  // start | end | focused (x) | result
  // ------------------------------
  // t | t | start | if x < end, set start, else, set start and remove end
  // f | t | start | if x < end, set start, else, set start and remove end
  // t | t | end   | if x > start, set end, else, set end and remove start
  // t | f | end   | if x > start, set end, else, set end and remove start
  // t | f | start | set start
  // f | t | end   | set end
  // f | f | start | set start
  // f | f | end   | set end
  const onSelect = useCallback<SelectRangeEventHandler>(
    (_range, x) => {
      // fs aka focused-start
      const fs = focusedInput !== RangeInputType.End;

      const s = startDate;
      const e = endDate;

      let res: DatesArr;
      if (fs && e) {
        res = x < e ? [x, e] : [x, null];
      } else if (!fs && s) {
        res = x > s ? [s, x] : [null, x];
      } else {
        res = fs ? [x, null] : [null, x];
      }
      handleChange(res);

      // focus the other input if it's blank
      if (fs && res[0] && !res[1]) {
        setFocusedInput(RangeInputType.End);
      } else if (!fs && res[1] && !res[0]) {
        setFocusedInput(RangeInputType.Start);
      }
    },
    [handleChange, focusedInput, startDate, endDate],
  );

  const clearDates = ([start, end]: DatesArr) => {
    handleChange([start, end]);
    setFocusedInput(start ? RangeInputType.End : RangeInputType.Start);
  };

  const modifiers = useMemo((): DayModifiers => {
    const now = new Date();

    const isDateAvailable = availabilityEnabled
      ? makeIsDateAvailable(
          {
            unavailableDays,
            bookingNoticeLimit,
            howFarCanGuestsBook,
            nightsMax,
            nightsMin,
            startDate: startDate && focusedInput === RangeInputType.End ? startDate : null,
            allowPastDates,
          },
          now,
          syncedUnavailableDays,
        )
      : undefined;

    return {
      firstOfMonth: (date) => date.getDate() === 1,
      lastOfMonth: (date) =>
        date.getDate() === new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(),
      // showToday: now,
      disabled: _.compact([
        isDateAvailable && ((date: Date) => !isDateAvailable(date)),
        !allowPastDates && {
          before: now,
        },
      ]),
    };
  }, [
    availabilityEnabled,
    unavailableDays,
    bookingNoticeLimit,
    howFarCanGuestsBook,
    startDate,
    focusedInput,
    nightsMax,
    nightsMin,
    allowPastDates,
  ]);

  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  const moreModifiers = useMemo(() => {
    if (!hoveredDate) return modifiers;

    if (focusedInput === RangeInputType.End && startDate && !endDate && hoveredDate > startDate) {
      return {
        ...modifiers,
        selected: startDate,
        range_start: startDate,
        range_end: hoveredDate,
        range_middle: (d: Date) => d > startDate && d < hoveredDate,
      };
    } else if (
      focusedInput === RangeInputType.Start &&
      !startDate &&
      endDate &&
      hoveredDate < endDate
    ) {
      return {
        ...modifiers,
        selected: endDate,
        range_end: endDate,
        range_start: hoveredDate,
        range_middle: (d: Date) => d < endDate && d > hoveredDate,
      };
    }

    return modifiers;
  }, [modifiers, startDate, endDate, focusedInput, hoveredDate]);

  const isMobile = useIsMobile();

  // close the dialog when we click outside
  const containerRef = useRef<HTMLDivElement>(null);
  useClickAway(containerRef, () => {
    setFocusedInput(null);
  });

  const calendar = (
    <DayPicker
      mode="range"
      selected={
        !startDate && endDate
          ? {
              from: endDate ?? undefined,
              to: undefined,
            }
          : {
              from: startDate ?? undefined,
              to: endDate ?? undefined,
            }
      }
      onDayMouseEnter={(date) => setHoveredDate(date)}
      onDayMouseLeave={(date) =>
        setHoveredDate((prev) => (prev?.getTime() === date.getTime() ? null : prev))
      }
      onSelect={onSelect}
      modifiers={moreModifiers}
      modifiersClassNames={{
        firstOfMonth: 'rdp-day_first_of_month',
        lastOfMonth: 'rdp-day_last_of_month',
      }}
      numberOfMonths={isMobile ? 1 : 2}
    />
  );

  return (
    <div className={className} style={style} ref={containerRef}>
      <div
        className={clsx('field has-addons', displayInline ? 'mb-4' : 'mb-0')}
        ref={popper.referenceRef}
      >
        <DateInput
          name="range-picker-start"
          type={RangeInputType.Start}
          focused={focusedInput === RangeInputType.Start}
          value={startDate}
          {...(noLabels
            ? {
                placeholder: labels[0],
              }
            : {
                label: labels[0],
                placeholder: 'Add date',
              })}
          onFocus={setFocusedInput}
          onClear={showClearDates ? () => clearDates([null, null]) : undefined}
          getReferenceProps={popper.getReferenceProps}
          leftRounded={leftRounded}
        />
        <DateInput
          name="range-picker-end"
          type={RangeInputType.End}
          focused={focusedInput === RangeInputType.End}
          value={endDate}
          {...(noLabels
            ? {
                placeholder: labels[1],
              }
            : {
                label: labels[1],
                placeholder: 'Add date',
              })}
          onFocus={setFocusedInput}
          onClear={showClearDates ? () => clearDates([startDate, null]) : undefined}
          getReferenceProps={popper.getReferenceProps}
        />
      </div>

      {displayInline ? (
        calendar
      ) : focusedInput != null ? (
        <div
          tabIndex={-1}
          role="dialog"
          {...popper.getPopperProps({
            className: 'box',
            style: {
              zIndex: 2,
            },
          })}
        >
          {calendar}
        </div>
      ) : null}
    </div>
  );
};
