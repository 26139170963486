import { format as dateFnsFormat, parse as parseDate, parseISO } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import type firebase from 'firebase/compat/app';
import * as _ from 'lodash-es';

import db from 'lib/db/shared';
import { parseInt, parseNum } from 'lib/utils';
import { pluralize } from 'lib/utils/string';
import type { FDay } from 'schemas/types';

const { Firestore } = db;

const MILLIS_IN_A_DAY = 86400000;

export type Duration = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export type TimeToMillisInput = number | string | Date | firebase.firestore.Timestamp;

export const timeToMillis = (time: TimeToMillisInput): number | null => {
  if (time == null) return null;

  if (typeof time === 'string')
    // using `Date.parse` not recommended:
    time = Date.parse(time);
  else if (time instanceof Date) time = time.getTime();
  else if (typeof time === 'object' && 'toMillis' in time) time = time.toMillis();

  return typeof time === 'number' && !Number.isNaN(time) && time >= 0 ? Math.floor(time) : null;
};

export const timeToDate = (date: TimeToMillisInput | null | undefined): Date | null => {
  if (date == null) return null;
  if (typeof date === 'number' && date > 0) return new Date(date);
  if (typeof date === 'string' && date.length >= 10) date = parseISO(date);
  if (date instanceof Date && !Number.isNaN(date.getTime())) return date;
  return null;
};

// Used to encode check-in / check-out time into hours
export const encodeTime = (
  hours: string | number,
  minutes: string | number,
  amPm: 'am' | 'pm',
): number => {
  hours = _.clamp(parseInt(hours) || 1, 1, 12);
  minutes = (parseInt(minutes) || 0) % 60;

  if (amPm === 'pm' && hours !== 12) hours += 12;
  else if (amPm === 'am' && hours === 12) hours = 0;

  return hours + minutes / 60;
};
export const decodeTime = (
  hoursFloat: number,
): { hours: number; minutes: number; amPm: 'am' | 'pm' } => {
  hoursFloat = _.clamp(parseNum(hoursFloat) || 0, 0, 23.999999);

  const hours = Math.floor(hoursFloat);
  const minutes = Math.floor((hoursFloat - hours) * 60);

  return {
    hours: hours % 12 || 12,
    minutes,
    amPm: hours < 12 ? 'am' : 'pm',
  };
};

/**
 * @example
 * timeAsHoursFloat('14:00') === 14
 * timeAsHoursFloat('12:15pm') === 12.25
 * timeAsHoursFloat('12:00am') === 0
 */
export const timeAsHoursFloat = (time: string) => {
  const m = time.match(/^(\d{1,2}):(\d{2})$/i);
  if (!m) return 0;

  const [, hour, minute] = m;

  return _.clamp(Number(hour), 0, 23) + _.clamp(Number(minute), 0, 59) / 60;
};

// Get 'days since epoch' from Date
export const dateToDay = (time?: TimeToMillisInput): FDay => {
  const millis = (time && timeToMillis(time)) || Date.now();
  const date = new Date(millis);
  date.setHours(12, 0, 0, 0);
  return Math.floor(date.getTime() / MILLIS_IN_A_DAY);
};

// Get Date from 'days since epoch'
export const dayToDate = (day: FDay): Date => {
  const date = new Date(0);
  date.setUTCSeconds((day + 1) * 24 * 60 * 60);
  return date;
};

export const getRangeBetweenDates = (from: Date, to: Date): FDay[] => {
  const toDay = dateToDay(to);
  const list = [];
  for (let i = dateToDay(from); i <= toDay; i++) {
    list.push(i);
  }

  return list;
};

export const getRangeBetweenDays = (from: FDay, to: FDay): FDay[] => {
  const days = Array.from({ length: (to ?? from) - from + 1 }, (_, i) => from + i);

  return days;
};

export const getTodayDay = () => dateToDay(new Date());

export const now = () => Firestore.Timestamp.now();

export const timestamp = (time: string | number | Date) => {
  if (typeof time === 'number') return Firestore.Timestamp.fromMillis(time);
  // using `Date.parse` not recommended:
  else if (typeof time === 'string') return Firestore.Timestamp.fromMillis(Date.parse(time));
  else return Firestore.Timestamp.fromDate(time);
};

export const FUNCTIONS_TIMEZONE = 'America/Los_Angeles';

export const getTimeInfoInTimezone = (date?: Date, timezone = FUNCTIONS_TIMEZONE) => {
  const m = (date || new Date())
    .toLocaleString('en-US', { timeZone: timezone, hour12: false })
    .match(/^(\d+)\/(\d+)\/(\d+),? (\d+):(\d+):(\d+)/);

  if (!m) return {};

  const [month, day, year, hour, minute, second] = m.map((a) => Number.parseInt(a, 10));

  return { month, day, year, hour, minute, second };
};

export const formatDate = (
  time: TimeToMillisInput,
  format: string,
  {
    timeZoneId,
  }: {
    timeZoneId?: string;
  } = {},
): string => {
  const millis = timeToMillis(time)!;

  let zonedTime = millis;
  if (timeZoneId) {
    try {
      const localOffsetMillis = -new Date().getTimezoneOffset() * 60 * 1000;
      const tzOffsetMillis = getTimezoneOffset(timeZoneId, millis);

      zonedTime = millis + tzOffsetMillis - localOffsetMillis;
    } catch (err) {
      console.error('utcToZonedTime error:', err);
    }
  }

  return dateFnsFormat(zonedTime, format);
};

const formatClock = (hoursFloat: string | number, allowNeg = false) => {
  hoursFloat = parseNum(hoursFloat) || 0;

  const isNeg = allowNeg && hoursFloat < 0;
  hoursFloat = _.clamp(Math.abs(hoursFloat), 0, 23.999999);

  const hours = Math.floor(hoursFloat);
  const minutes = Math.floor((hoursFloat - hours) * 60);

  return `${allowNeg ? (isNeg ? '-' : '+') : ''}${_.padStart(
    hours.toString(),
    2,
    '0',
  )}:${_.padStart(minutes.toString(), 2, '0')}`;
};

export const dayAndTimeToDate = (
  dayString: string,
  hoursFloat: string | number, // hours. Is a floating point number e.g. 7.5 is 7:30AM
  timezoneUtcOffset = 0, // in minutes e.g. PST (-08:00) is -480
) => {
  const date = parseDate(
    `${dayString} ${formatClock(hoursFloat)} ${formatClock(timezoneUtcOffset / 60, true)}`,
    'yyyy-MM-dd HH:mm xxx',
    new Date(),
  );
  if (Number.isNaN(date.getTime())) return null;
  return date;
};

const DURATIONS: Record<string, number | undefined> = {
  ms: 1,
  millis: 1,
  millisecond: 1,
  second: 1000,
  s: 1000,
  minute: 60 * 1000,
  hour: 60 * 60 * 1000,
  day: 24 * 60 * 60 * 1000,
  week: 7 * 24 * 60 * 60 * 1000,
};
/**
 * @example
 * durationToMillis('10 minutes') === 360000
 */
export const durationToMillis = (duration: string): number | null => {
  const match = duration.match(/^([\d,]+)\s+(\w+)$/);
  if (!match) return null;

  const [, valueStr, unit] = match;

  const value = Number(valueStr.replace(/,/g, ''));
  if (!(value >= 0)) return null;

  let unitMillis: number | undefined;
  for (const key in DURATIONS) {
    if (key === unit || `${key}s` === unit) {
      unitMillis = DURATIONS[key];
      break;
    }
  }
  if (unitMillis == null) return null;

  return Math.round(value * unitMillis);
};

export const convertMSToDuration = (difference: number) => {
  let timeLeft = null;

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export const calculateTimeLeft = (
  from: TimeToMillisInput,
  to: TimeToMillisInput,
): Duration | null => {
  const fromMillis = timeToMillis(from) ?? 0;
  const toMillis = timeToMillis(to) ?? 0;
  const difference = fromMillis - toMillis;

  return convertMSToDuration(difference);
};

export const millisToDurationString = (millis: number): string => {
  const time_abbreviations = {
    days: 'day',
    hours: 'hour',
    minutes: 'min',
  };

  const timeLeft = {
    days: Math.floor(millis / (1000 * 60 * 60 * 24)),
    hours: Math.floor((millis / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((millis / 1000 / 60) % 60),
    seconds: Math.floor((millis / 1000) % 60),
  };

  return Object.keys(timeLeft)
    .filter((item) => item !== 'seconds' && (timeLeft as any)[item] !== 0)
    .reduce(
      (prev: string, cur: string) =>
        `${prev} ${(timeLeft as any)[cur]} ${pluralize(
          (timeLeft as any)[cur],
          (time_abbreviations as any)[cur],
        )}`,
      '',
    );
};
